import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { useLastLocation } from "react-router-last-location";
import { Helmet } from "react-helmet";
import DataStore from "../../DataStore";
import Auth from "../../Auth";
import DataSource from "../../DataSource";
import { UserProvider } from '../../UserContext';
import './App.scss';
import Header from "../Header/Header";
import Navigation from "../Navigation/Navigation";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Logout from "../../pages/Logout/Logout";
import DisabledOverlay from "../../components/DisabledOverlay/DisabledOverlay";
import SearchFilters from "../../components/SearchFilters/SearchFilters";
import FlashMessage, {FLASH_SUCCESS} from "../../components/FlashMessage/FlashMessage";
import {
    FILTER_FAVOURITE,
    RECOMMENDATION_TYPES,
    TYPE_BEACH,
    TYPE_EAT_AND_DRINK,
    TYPE_NIGHTLIFE,
    TYPE_SEE_AND_DO
} from "../../constants";
import LoadingApp from "../../components/LoadingApp/LoadingApp";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import GenericError from "../../components/GenericError/GenericError";

const Hostel = lazy(() => import('../../pages/Hostel/Hostel' /* webpackChunkName: "js/page-hostel" */));
const Map = lazy(() => import('../../pages/Map/Map' /* webpackChunkName: "js/page-map" */));
const Events = lazy(() => import('../../pages/Events/Events' /* webpackChunkName: "js/page-events" */));
const Noticeboard = lazy(() => import('../../pages/Noticeboard/Noticeboard' /* webpackChunkName: "js/page-noticeboard" */));
const Recommendations = lazy(() => import('../../pages/Recommendations/Recommendations' /* webpackChunkName: "js/page-recommendations" */));
const Recommendation = lazy(() => import('../../pages/Recommendation/Recommendation' /* webpackChunkName: "js/page-recommendation" */));
const Routes = lazy(() => import('../../pages/Routes/Routes' /* webpackChunkName: "js/page-routes" */));
const UsefulInfo = lazy(() => import('../../pages/UsefulInfo/UsefulInfo' /* webpackChunkName: "js/page-useful-info" */));
const Search = lazy(() => import('../../pages/Search/Search' /* webpackChunkName: "js/page-search" */));
const Specials = lazy(() => import('../../pages/Specials/Specials' /* webpackChunkName: "js/page-specials" */));
const NotFound = lazy(() => import('../../pages/NotFound/NotFound' /* webpackChunkName: "js/page-not-found" */));
const LoginSignup = lazy(() => import('../../pages/LoginSignup/LoginSignup' /* webpackChunkName: "js/page-login-signup" */));
const Account = lazy(() => import('../../pages/Account/Account' /* webpackChunkName: "js/page-account" */));

const App = () => {
    const location = useLocation();
    const lastLocation = useLastLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [hostel, setHostel] = useState({});
    const [hasError, setHasError] = useState(false);
    const [user, setUser] = useState(DataStore.getItem('user', {}));
    const [accessToken, setAccessToken] = useState(DataStore.getItem('accessToken'));
    const [appliedFilters, setAppliedFilters] = useState({});
    const [isMoreVisible, setIsMoreVisible] = useState(false);
    const [isSearchFiltersVisible, setIsSearchFiltersVisible] = useState(false);
    const [isAppFixed, setIsAppFixed] = useState(false);
    const [flashMsg, setFlashMsg] = useState({});
    const [noticeboardLastSeen, setNoticeboardLastSeen] = useState(DataStore.getItem('noticeboardLastSeen', null));
    const [hasNewNotices, setHasNewNotices] = useState(false);

    const fixedPositionUrls = ['/account'];
    const hideNavFromUrls = ['/login', '/signup', '/forgot'];

    useEffect(() => {
        getHostelData();
        catchInstallPrompt();
        validateUserToken();
    }, []);

    useEffect(() => {
        calcHasNewNotices();
    }, [noticeboardLastSeen]);

    useEffect(() => {
        if (location !== lastLocation) {
            setIsAppFixed(fixedPositionUrls.includes(location.pathname));
            setIsSearchFiltersVisible(false);
        }
    }, [location]);

    const getHostelData = async () => {
        await DataSource.getSite()
            .then(response => {
                setHostel(response);
            })
            .catch(error => {
                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const catchInstallPrompt = () => {
        let deferredPrompt;

        /*
            We can hijack the Add to Homescreen prompt and ask it on an event instead
         */
        window.addEventListener('beforeinstallprompt', (e) => {
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            // this.addToHomeBtn.addEventListener('click', (e) => {
            // 	// hide our user interface that shows our A2HS button
            // 	this.addToHomeBtn.style.display = 'none';
            // 	// Show the prompt
            // 	deferredPrompt.prompt();
            // 	// Wait for the user to respond to the prompt
            // 	deferredPrompt.userChoice
            // 		.then((choiceResult) => {
            // 			if (choiceResult.outcome === 'accepted') {
            // 				console.log('User accepted the A2HS prompt');
            // 			} else {
            // 				console.log('User dismissed the A2HS prompt');
            // 			}
            // 			deferredPrompt = null;
            // 		});
            // });
        });

        /*
            On an event, can add to the cache ie. when clicking "download website" for example
            * Here we can download the website pages which can be navigated to
            * We can download the necessary API calls for those pages
            * Can can download the images and any other required assets
         */
        // this.downloadBtn.addEventListener('click', (e) => {
        // 	this.addToCache('route-cache', [
        // 		'/todos', // Precache any nav pages
        // 		'/done',
        // 		'/gallery',
        // 		'http://localhost:3000/todos', // Precache API urls
        // 		'http://localhost:3000/done'
        // 	]).then(() => {
        // 		this.addToCache('image-cache', [
        // 			'/images/andy.jpg' // Precache reqd images for above pages
        // 		]);
        // 	});
        // });
    }

    const addToCache = async (cache, urls) => {
        const myCache = await window.caches.open(cache);
        await myCache.addAll(urls);
    };

    const showMorePanel = () => {
        setIsMoreVisible(true);
        setIsAppFixed(true);
    }

    const hideMorePanel = () => {
        setIsMoreVisible(false);
        setIsAppFixed(false);
    }

    const setAppFixed = (value) => {
        setIsAppFixed(value);
    }

    const loginUser = (response) => {
        return new Promise((resolve, reject) => {
            setUserState(response.user);
            setAccessTokenState(response.access_token, () => {
                resolve();
            });
        });
    }

    const setUserState = (user, successMsg = '') => {
        setUser(user);
        DataStore.setItem('user', user);
        if (successMsg) {
            setFlashMsg({type: FLASH_SUCCESS, message: successMsg});
        }
    }

    const setAccessTokenState = (accessToken, onSuccess = () => {}) => {
        setAccessToken(accessToken);
        DataStore.setItem('accessToken', accessToken);
        onSuccess();
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            Auth.logout(accessToken)
                .then(response => {
                    logoutCleanupState();
                    resolve();
                })
                .catch(error => {
                    logoutCleanupState();
                    reject();
                });
        });
    }

    const logoutCleanupState = () => {
        setUser({});
        setAccessToken(null);
        DataStore.clearAll();
    }
    
    const isLoggedIn = () => {
        return !!accessToken;
    }

    /*
        Check if a user is logged in without sending a 401 response
        (so we are not redirected to login)
    */
    const checkUserIsLoggedIn = () => {
        if (accessToken) {
            return Auth.checkLoggedIn(accessToken)
                .then(response => {
                    return response.data.isLoggedIn;
                })
                .catch(error => {
                    return false;
                });
        }

        return false;
    };

    /*
        Log user out if token is expired
        Or refresh the token to reset the 30 day expiry
    */
    const validateUserToken = async () => {
        let isLoggedIn = await checkUserIsLoggedIn();
        if (accessToken && !isLoggedIn) {
            await logout();
        } else if (accessToken && isLoggedIn) {
            await refreshUserToken();
        }
    };

    const refreshUserToken = () => {
        Auth.refreshToken(accessToken)
            .then(response => {
                setUserState(response.data.user);
                setAccessTokenState(response.data.access_token);
            });
    };
    
    const buildUserProvider = () => {
        return {
            user: user,
            accessToken: accessToken,
            setUser: setUserState,
            loginUser: loginUser,
            isLoggedIn: isLoggedIn,
            refreshUserToken: refreshUserToken,
            setFlashMsg: setFlashMsg
        };
    }

    const updateFilters = filters => {
        setAppliedFilters(filters);
    }

    const hasFiltersApplied = () => {
        return Object.keys(appliedFilters).length > 0;
    };

    const filterRecommendations = recommendations => {
        if (hasFiltersApplied()) {
            let newData = [];
            recommendations.forEach(item => {
                Object.keys(appliedFilters).forEach(key => {
                    const isFavourite = user && user.id && item.favouritesArray.includes(user.id);
                    if (item.tags.includes(key) || (key === FILTER_FAVOURITE && isFavourite)) {
                        let exists = newData.find(value => value.id === item.id)
                        if (!exists) {
                            newData.push(item);
                        }
                    }
                });
            });

            return newData;
        }

        return recommendations;
    };

    const calcHasNewNotices = async() => {
        let hasNewNotices = false;
        let latestNotice = await DataSource.getLatestNotice();

        if (!noticeboardLastSeen || !latestNotice) {
            hasNewNotices = false;
        }

        if (!noticeboardLastSeen && latestNotice) {
            hasNewNotices = true;
        }

        if (noticeboardLastSeen && latestNotice) {
            let lastSeen = new Date(noticeboardLastSeen);
            let latest = new Date(latestNotice.created_at);

            hasNewNotices = (latest > lastSeen);
        }

        setHasNewNotices(hasNewNotices);
    };

    const RenderHostel = () => {
        return <Hostel hostel={hostel} />;
    };

    const RenderMap = () => {
        return <Map
            hostel={hostel}
            showFilters={setIsSearchFiltersVisible}
            hasFiltersApplied={hasFiltersApplied()}
            appliedFilters={appliedFilters}
            filterRecommendations={filterRecommendations}
        />;
    };

    const RenderEvents = () => {
        return <Events />;
    };

    const RenderNoticeboard = () => {
        return <Noticeboard
            setAppFixed={setAppFixed}
            setFlashMsg={setFlashMsg}
            setNoticeboardLastSeen={setNoticeboardLastSeen}
        />;
    };

    const RenderLogin = () => {
        return <LoginSignup type="login" />;
    };

    const RenderSignup = () => {
        return <LoginSignup type="signup" />;
    };

    const RenderLogout = () => {
        return <Logout logout={logout} />;
    };

    const RenderSearch = () => {
        return <Search hostelTitle={hostel.title} />;
    };

    const RenderSpecials = () => {
        return <Specials />;
    };

    const RenderRoutes = () => {
        return <Routes />;
    };

    const RenderUsefulInfo = () => {
        return <UsefulInfo />;
    };

    const RenderRecommendation = type => {
        return <Recommendation
            type={type}
            isMoreVisible={isMoreVisible}
            setAppFixed={setAppFixed}
            setFlashMsg={setFlashMsg}
        />;
    };

    const RenderSeeAndDo = () => {
        return <Recommendations type={TYPE_SEE_AND_DO} />;
    };

    const RenderEatAndDrink = () => {
        return <Recommendations type={TYPE_EAT_AND_DRINK} />;
    };

    const RenderNightlife = () => {
        return <Recommendations type={TYPE_NIGHTLIFE} />;
    };

    const RenderBeaches = () => {
        return <Recommendations type={TYPE_BEACH} />;
    };

    const RenderRecommendations = () => {
        return <Recommendations
            type={null}
            showFilters={setIsSearchFiltersVisible}
            hasFiltersApplied={hasFiltersApplied()}
            appliedFilters={appliedFilters}
            updateFilters={updateFilters}
            filterRecommendations={filterRecommendations}
        />;
    };

    let background = location.state && location.state.background;

    if (isLoading) {
        return <LoadingApp showHeader={true} showNav={true} />;
    }

    if (hasError) {
        return <GenericError />;
    }

    return (
        <UserProvider value={buildUserProvider()}>
            <React.Fragment>
                {Object.keys(hostel).length > 0 ?
                    <Helmet>
                        <title>{hostel.title} - Hostelify</title>
                        <meta property="og:title" content={`${hostel.title} - Hostelify`} />
                        <meta name="description" content={hostel.short_description} />
                        <meta property="og:description" content={hostel.short_description} />
                        <meta property="og:image" content={`${process.env.REACT_APP_FILE_URL}${hostel.images[0]}`} />
                    </Helmet>
                : ''}
                <FlashMessage handleClose={() => setFlashMsg({})} message={flashMsg} />
                <div className={`App ${isAppFixed ? 'fixed' : ''}`} id="App">
                    <DisabledOverlay
                        isDisabled={isMoreVisible}
                        handleClick={isMoreVisible ? hideMorePanel : () => {}}
                    >
                        <Header hostelTitle={hostel.title} />
                        <section style={{position: 'relative'}}>
                            <ErrorBoundary>
                                <Suspense fallback={<LoadingApp />}>
                                    <Switch location={background || location}>
                                        <Route path="/" exact render={RenderHostel} />
                                        <Route path="/map" render={RenderMap} />
                                        <Route path="/events" exact render={RenderEvents} />
                                        <Route path="/event/:date/:slug" render={RenderEvents} />
                                        <Route path="/noticeboard" exact render={RenderNoticeboard} />
                                        <Route path="/see-and-do" exact render={RenderSeeAndDo} />
                                        <Route path="/eat-and-drink" exact render={RenderEatAndDrink} />} />
                                        <Route path="/nightlife" exact render={RenderNightlife} />
                                        <Route path="/beaches" exact render={RenderBeaches} />
                                        <Route path="/routes" exact render={RenderRoutes} />
                                        <Route path="/route/:slug" exact render={RenderRoutes} />
                                        <Route path="/useful-info" exact render={RenderUsefulInfo} />
                                        <Route path="/specials" exact render={RenderSpecials} />
                                        {RECOMMENDATION_TYPES.map(type => {
                                            return <Route key={type} path={`/${type}/:slug`} render={() => RenderRecommendation(type)} />
                                        })}
                                        <Route path="/recommendations" exact render={RenderRecommendations} />
                                        <Route path="/not-found" exact component={NotFound} />
                                    </Switch>
                                </Suspense>
                                <SearchFilters
                                    isVisible={isSearchFiltersVisible}
                                    setIsVisible={setIsSearchFiltersVisible}
                                    appliedFilters={appliedFilters}
                                    updateFilters={updateFilters}
                                />
                            </ErrorBoundary>
                        </section>
                    </DisabledOverlay>
                    <Suspense fallback={<LoadingPage handleBack={() => {}} />}>
                        <Switch>
                            {background ?
                                RECOMMENDATION_TYPES.map(type => {
                                    return <Route key={type} path={`/${type}/:slug`} render={() => RenderRecommendation(type)} />
                                })
                                : ''}
                        </Switch>
                    </Suspense>
                    {!hideNavFromUrls.includes(location.pathname) ?
                        <Navigation
                            isMoreVisible={isMoreVisible}
                            handleShowMore={showMorePanel}
                            handleHideMore={hideMorePanel}
                            moreNavigation={hostel.settings.moreNavigation}
                            hasNewNotices={hasNewNotices}
                        />
                    : ''}
                </div>

                <ErrorBoundary isAbsolute={true}>
                    <Suspense fallback={<LoadingApp />}>
                        <Switch location={background || location}>
                            <Route path="/logout/:redirectUrl" render={RenderLogout} />
                            <Route path="/logout" exact render={RenderLogout} />
                            <Route path="/login" exact render={RenderLogin} />
                            <Route path="/signup" exact render={RenderSignup} />
                            <PrivateRoute path="/account" exact component={Account} />
                            <Route path="/search" exact render={RenderSearch} />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </React.Fragment>
        </UserProvider>
    );

};

export default withRouter(App);
