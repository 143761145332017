import React from "react";

const WhiteCloseCircleIcon = (props) => {

    return (
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g id="plus-filter-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-476.000000, -136.000000)">
                    <g id="White-x" transform="translate(476.000000, 136.000000)">
                        <circle id="Oval" fill="#FFFFFF" cx="12" cy="12" r="12" />
                        <g id="Group-3" transform="translate(5.636039, 5.636039)" fill="#3F3F3F">
                            <rect id="Rectangle" transform="translate(6.363961, 6.363961) rotate(-45.000000) translate(-6.363961, -6.363961) " x="5.36396103" y="-1.63603897" width="2" height="16" rx="1" />
                            <rect id="Rectangle" transform="translate(6.363961, 6.363961) rotate(-315.000000) translate(-6.363961, -6.363961) " x="5.36396103" y="-1.63603897" width="2" height="16" rx="1" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default WhiteCloseCircleIcon;