import React from "react";
import PropTypes from "prop-types";
import styles from "./ImageBanner.module.scss";
import Image from "../Image/Image";

const ImageBanner = (props) => {

    return (
        <div className={`${styles.container} ${props.className}`}>
            <Image src={props.image} />
        </div>
    );

};

ImageBanner.propTypes = {
    image: PropTypes.string.isRequired,
    className: PropTypes.string
};

ImageBanner.defaultProps = {
    className: ''
};

export default ImageBanner;