import React from "react";

const SmallIconBeach = (props) => {

    return (
        <svg viewBox="0 0 20 17">
            <title>Beach_Small</title>
            <defs>
                <polygon id="small-beach-path-1" points="2.84217094e-14 0 19.8359222 0 19.8359222 5.66346 2.84217094e-14 5.66346"/>
            </defs>
            <g id="FILTER-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-626.000000, -46.000000)">
                    <g id="Beach_Small" transform="translate(626.000010, 46.040000)">
                        <g id="Group-8" transform="translate(0.000000, 0.000000)">
                            <path d="M13.4786462,16.302268 C12.2717662,16.275388 11.1709662,15.923188 10.1641662,15.293108 C9.34604623,14.781108 8.56344623,14.212548 7.75352623,13.686948 C7.13552623,13.285908 6.47128623,13.004708 5.70920623,13.056308 C5.12660623,13.095708 4.59228623,13.293428 4.07948623,13.558028 C3.40724623,13.904828 2.79948623,14.347468 2.21200623,14.820628 C2.05700623,14.945508 1.90028623,15.076508 1.72456623,15.165788 C1.20020623,15.432228 0.572606225,15.265788 0.232086225,14.789228 C-0.108233775,14.312868 -0.0814737745,13.639908 0.360046225,13.256068 C1.60032623,12.177788 2.93616623,11.248428 4.55464623,10.819148 C6.08164623,10.414148 7.50404623,10.709148 8.82132623,11.533148 C9.63956623,12.045028 10.4223262,12.613308 11.2309262,13.141108 C12.0204462,13.656428 12.8780062,13.952588 13.8377662,13.853428 C14.6596062,13.768508 15.4197662,13.482108 16.1290462,13.076548 C16.7292862,12.733348 17.3063662,12.348428 17.8841262,11.967388 C18.5607662,11.521108 19.3726862,11.698468 19.7080462,12.380788 C19.9474862,12.867908 19.8428062,13.488748 19.4093262,13.813268 C18.0302062,14.845788 16.5670462,15.724828 14.8587662,16.100708 C14.4058862,16.200348 13.9390862,16.236788 13.4786462,16.302268" id="Fill-1" fill="#FF6E75"/>
                            <path d="M13.4574382,10.981096 C12.2724382,10.951936 11.1899182,10.606616 10.1976382,9.993256 C9.48443823,9.552376 8.80439823,9.058096 8.10703823,8.591376 C7.55163823,8.219656 6.98183823,7.878896 6.30615823,7.766496 C5.67815823,7.662016 5.08707823,7.797456 4.51347823,8.036736 C3.64971823,8.397016 2.90775823,8.949096 2.17875823,9.523776 C1.97803823,9.682016 1.75623823,9.839096 1.51803823,9.918976 C1.00115823,10.092296 0.438958225,9.842536 0.166038225,9.368656 C-0.102481775,8.902416 -0.0478417745,8.291056 0.360878225,7.935896 C1.60175823,6.857616 2.93751823,5.928536 4.55559823,5.498856 C6.07459823,5.095456 7.49115823,5.388336 8.80515823,6.203256 C9.51211823,6.641656 10.1855982,7.133816 10.8774382,7.596816 C11.5002382,8.013616 12.1453582,8.381296 12.9021582,8.502976 C13.7185582,8.634176 14.4924382,8.471536 15.2462382,8.173576 C16.1378382,7.821096 16.9439182,7.316016 17.7223982,6.763616 C17.9620382,6.593576 18.1998782,6.427816 18.5091182,6.400816 C19.0430782,6.354256 19.5351982,6.641656 19.7411582,7.135136 C19.9409182,7.613816 19.8149182,8.196056 19.3941582,8.508096 C18.0105582,9.534176 16.5478782,10.414256 14.8387582,10.786696 C14.3857182,10.885376 13.9182782,10.918096 13.4574382,10.981096" id="Fill-3" fill="#FF6E75"/>
                            <g id="Group-7" transform="translate(0.001897, 0.000000)">
                                <mask id="small-beach-mask-2" fill="white">
                                    <use xlinkHref="#small-beach-path-1"/>
                                </mask>
                                <g id="Clip-6"/>
                                <path d="M13.4614371,5.66346 C12.0468371,5.64098 10.8169171,5.1187 9.67095712,4.35458 C9.02811712,3.9259 8.40295712,3.47046 7.75527712,3.04942 C7.14979712,2.65582 6.49835712,2.37442 5.75175712,2.41354 C5.14763712,2.44518 4.59455712,2.65114 4.06387712,2.9255 C3.36187712,3.28846 2.72827712,3.7527 2.12283712,4.25658 C1.86111712,4.47442 1.58267712,4.64678 1.22919712,4.6527 C0.741117124,4.66086 0.299877124,4.37622 0.104437124,3.92862 C-0.0904428759,3.4823 -0.0157228759,2.94282 0.354997124,2.61994 C1.56395712,1.5669 2.86639712,0.66174 4.43295712,0.21198 C5.99703712,-0.23706 7.45787712,0.04486 8.81531712,0.8913 C9.63987712,1.40546 10.4270371,1.97938 11.2417171,2.5099 C12.0443571,3.03258 12.9172771,3.32358 13.8905171,3.20882 C14.6963571,3.11382 15.4437171,2.83114 16.1401171,2.43006 C16.7335971,2.0883 17.3045171,1.70642 17.8773971,1.33014 C18.4353171,0.96366 19.0572771,0.99534 19.4829571,1.43046 C19.9758371,1.9343 19.9579971,2.75962 19.3949571,3.18282 C18.0233571,4.2137 16.5629971,5.0859 14.8614371,5.45978 C14.4020371,5.56074 13.9284771,5.59734 13.4614371,5.66346" id="Fill-5" fill="#FF6E75" mask="url(#small-beach-mask-2)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default SmallIconBeach;