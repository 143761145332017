import DataSource from "./DataSource";

const Auth = {

    login(data) {
        return DataSource.postData('/api/auth/login', null, data);
    },

    loginViaFacebook(fbToken) {
        return DataSource.postData('/api/auth/login/facebook', null, {access_token: fbToken});
    },

    loginViaGoogle(googleToken) {
        return DataSource.postData('/api/auth/login/google', null, {access_token: googleToken});
    },

    refreshToken(accessToken) {
        return DataSource.postData('/api/auth/refresh', accessToken);
    },

    checkLoggedIn(accessToken) {
        return DataSource.postData('/api/auth/checkLoggedIn', accessToken);
    },

    logout(accessToken) {
        return DataSource.postData('/api/auth/logout', accessToken);
    },

    register(data) {
        return DataSource.postData('/api/auth/register', null, data);
    }

};

export default Auth;
