import * as Sentry from "@sentry/react";

export default {

    removeItemByValue(array, value) {
        let index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
        }

        return array;
    },

    toggleLikeWithinLikedArray(user, record, allRecords, arrayName = 'likesArray') {
        let data = [...allRecords];
        data.find((obj, key) => {
            if (obj.id === record.id) {
                if (obj[arrayName].includes(user.id)) {
                    data[key][arrayName] = this.removeItemByValue(obj.likesArray, user.id);
                } else {
                    data[key][arrayName].push(user.id);
                }
                return true; // stop searching
            }
        });

        return data;
    },

    setUrlBeforeLogin(url) {
        sessionStorage.setItem('urlBeforeLoginRedirect', encodeURIComponent(url));
    },

    getUrlBeforeLogin() {
        let url = sessionStorage.getItem('urlBeforeLoginRedirect');
        if (url) {
            return decodeURIComponent(url);
        }

        return '';
    },

    strSlug(text) {
        return text
            .toLowerCase()
            .replace(/[^\w ]+/g,'')
            .replace(/ +/g,'-');
    },

    noWidows(text) {
        if (text) {
            return text.replace(/\s(?=[^\s]*$)/g, "&nbsp;");
        }
    },

    debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(context, args);
            }, wait);
        }
    },

    handleError(error, errorInfo) {
        console.error(error, errorInfo);
        Sentry.captureMessage(error, errorInfo);
    }

}