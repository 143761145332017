import axios from "axios/index";
import globals from "./globals";

const DataSource = {

    /*
        SITE
    */
    getSite() {
        return this.getData(`/api/site/${process.env.REACT_APP_SITE_ID}`);
    },

    /*
        SPECIALS
    */
    getSpecials() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/specials`);
    },

    /*
        NOTICES
    */
    getNotices() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/notices`);
    },

    getLatestNotice() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/latestNotice`);
    },

    addNotice(accessToken, data) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/notice`, accessToken, data);
    },

    addNoticeImage(accessToken, noticeId, file) {
        return this.postFile(`/api/${process.env.REACT_APP_SITE_ID}/${noticeId}/noticeImage`, accessToken, file);
    },

    toggleNoticeLike(accessToken, id) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleNoticeLike`, accessToken, {notice_id: id});
    },

    toggleCommentLike(accessToken, id) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleCommentLike`, accessToken, {comment_id: id});
    },

    addNoticeComment(accessToken, data) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/noticeComment`, accessToken, data);
    },

    /*
        EVENTS
    */
    getEvents() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/events`);
    },

    toggleInterested(accessToken, id) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleInterested`, accessToken, {event_id: id});
    },

    /*
        RECOMMENDATIONS
    */
    getRecommendations(type = '') {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/recommendations${type ? `/${type}` : ''}`);
    },

    getRecommendation(type, slug) {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/recommendation/${type}/${slug}`);
    },

    toggleFavourite(accessToken, id) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleFavourite`, accessToken, {recommendation_id: id});
    },

    toggleLikeDislike(accessToken, id, type) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleLikeDislike`, accessToken, {recommendation_id: id, type: type});
    },

    addRecommendationComment(accessToken, id, content) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/recommendationComment`, accessToken, {recommendation_id: id, content: content});
    },

    /*
        ROUTES
    */
    getRoutes() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/routes`);
    },

    getRoute(slug) {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/route/${slug}`);
    },

    toggleFavouriteRoute(accessToken, id) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/toggleFavouriteRoute`, accessToken, {route_id: id});
    },

    /*
        COMMENTS
    */
    addCommentImage(accessToken, commentId, file) {
        return this.postFile(`/api/${process.env.REACT_APP_SITE_ID}/${commentId}/commentImage`, accessToken, file);
    },

    /*
        GENERAL
    */
    getUsefulInfo() {
        return this.getData(`/api/${process.env.REACT_APP_SITE_ID}/usefulInfo`);
    },

    search(search) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/search`, null, {search: search});
    },

    /*
        USER & ACCOUNT
    */
    updateProfile(accessToken, data) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/profile`, accessToken, data);
    },

    updateUserSettings(accessToken, data) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/settings`, accessToken, {options: data});
    },

    changeAvatar(accessToken, file) {
        return this.postFile(`/api/${process.env.REACT_APP_SITE_ID}/changeAvatar`, accessToken, file);
    },

    deleteAccount(accessToken) {
        return this.postData(`/api/${process.env.REACT_APP_SITE_ID}/deleteAccount`, accessToken);
    },

    getData(url, accessToken = null) {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}${url}`, this.getConfig(accessToken))
                .then(this.handleResponse)
                .then(result => {
                    if (!result.ok) {
                        throw new Error(`HTTP status ${result.status} from GET request URL ${url}`);
                    }

                    return result.json();
                })
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    //todo: Handle errors gracefully
                    //todo: Log errors
                    reject(error);
                });
        });
    },

    postData(url, accessToken = null, data = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + url,
                data: {site_id: process.env.REACT_APP_SITE_ID, ...data},
                ...this.getConfig(accessToken)
            })
                .then(this.handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    this.handleErrors(url, response);
                    reject(response);
                });
        });
    },

    postFile(url, accessToken = null, file) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('file', file);
            axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + url,
                data: formData,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(this.handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    this.handleErrors(url, response);
                    reject(response);
                });
        });
    },

    getConfig(accessToken) {
        let headers = {'Accept': 'application/json', 'Content-Type': 'application/json'};
        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return {headers: headers};
    },

    handleErrors(url, response) {
        if (response && ((response.response && response.response.status !== 422 && response.response.status !== 401) || !response.response)) {
            //todo: Log errors
            //globals.handleError(response.message);
        }

        if (response && response.response && response.response.status === 401 && !url.includes('/login')) {
            this.redirectToLogin();
        }
    },

    handleResponse(response) {
        if (response.status === 401) {
            this.redirectToLogin();
        }

        if (response.status === 404) {
            window.location = '/not-found';
        }

        return response;
    },

    redirectToLogin() {
        globals.setUrlBeforeLogin(window.location.pathname);
        window.location = '/logout/login';
    }

};

export default DataSource;
