import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from 'react-router-dom';
import HostelIcon from "../../components/NavIcons/HostelIcon";
import MapIcon from "../../components/NavIcons/MapIcon";
import EventsIcon from "../../components/NavIcons/EventsIcon";
import NoticesIcon from "../../components/NavIcons/NoticesIcon";
import MoreIcon from "../../components/NavIcons/MoreIcon";
import MorePanel from "../MorePanel/MorePanel";
import { RECOMMENDATION_TYPES } from "../../constants";

const Navigation = (props) => {
    
    const location = useLocation();

    const isActive = (search) => {
        if (props.isMoreVisible) {
            return false;
        } else if (!Array.isArray(search) && search === '/' && location.pathname === '/') {
            return true;
        } else if (!Array.isArray(search) && search !== '/') {
            return location.pathname.includes(search);
        } else if (Array.isArray(search)) {
            let didFind = false;
            search.forEach(item => {
                if (location.pathname.includes(item)) {
                    didFind = true;
                }
            });

            return didFind;
        }

        return false;
    };

    const activeString = (search) => {
        return isActive(search) ? 'active' : '';
    }

    const handleToggleMore = event => {
        event.preventDefault();
        if (props.isMoreVisible) {
            props.handleHideMore();
        } else {
            props.handleShowMore();
        }
    };
    
    return (
        <React.Fragment>
            <nav>
                <div className="inner">
                    <Link to="/" className={`nav-item ${activeString('/')}`}>
                        <HostelIcon isActive={isActive('/')} />
                        <div>Hostel</div>
                    </Link>
                    <Link to="/map" className={`nav-item ${activeString('/map')}`}>
                        <MapIcon isActive={isActive(['/map', '/recommendations'])} />
                        <div>Map</div>
                    </Link>
                    <Link to="/events" className={`nav-item ${activeString(['/events', '/event'])}`}>
                        <EventsIcon isActive={isActive(['/events', '/event'])} />
                        <div>Events</div>
                    </Link>
                    <Link to="/noticeboard" className={`nav-item ${activeString('/noticeboard')}`}>
                        <NoticesIcon isActive={isActive('/noticeboard')} hasNewNotices={props.hasNewNotices} />
                        <div>Noticeboard</div>
                    </Link>
                    <a href="#" className={`nav-item ${props.isMoreVisible ? 'active' : ''}`} onClick={handleToggleMore}>
                        <MoreIcon isActive={props.isMoreVisible || isActive([...RECOMMENDATION_TYPES, '/routes', '/route', '/useful-info', '/specials'])} />
                        <div>More</div>
                    </a>
                </div>
            </nav>
            <MorePanel
                isMoreVisible={props.isMoreVisible}
                handleHideMore={props.handleHideMore}
                moreNavigation={props.moreNavigation}
            />
        </React.Fragment>
    );
    
};

Navigation.propTypes = {
    isMoreVisible: PropTypes.bool.isRequired,
    handleShowMore: PropTypes.func.isRequired,
    handleHideMore: PropTypes.func.isRequired,
    moreNavigation: PropTypes.object.isRequired,
    hasNewNotices: PropTypes.bool
};

export default Navigation;