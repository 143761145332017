import React from "react";
import PropTypes from "prop-types";
import styles from "./LoadingOverlay.module.scss";
import Loading from "../Loading/Loading";

const LoadingOverlay = (props) => {

    return (
        <div className={props.isLoading ? styles.disabled : ''}>
            {props.isLoading ?
                <div className={styles.loader}>
                    <Loading width={props.width} height={props.height} />
                </div>
            : ''}
            <div className={styles.overlay} />
            {props.children}
        </div>
    );

};

LoadingOverlay.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
};

LoadingOverlay.defaultProps = {
    isLoading: false,
    width: '80px',
    height: '80px'
}

export default LoadingOverlay;