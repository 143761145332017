import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";

const Button = (props) => {

    return (
        <div className={`${styles.button} ${props.isActive && styles.active}`} onClick={props.handleClick}>
            {props.children}
        </div>
    );

};

Button.propTypes = {
    handleClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool
};

export default Button;