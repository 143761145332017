import React from "react";
import PropTypes from "prop-types";
import HasNotice from "../HasNotice/HasNotice";

const NoticesIcon = (props) => {

    if (props.isActive) {
        return (
            <div className="position-relative">
                {props.hasNewNotices ? <HasNotice /> : ''}
                <svg width="26px" height="24px" viewBox="0 0 26 24">
                    <title>Notices_Red</title>
                    <defs>
                        <filter colorInterpolationFilters="auto" id="notices-icon-filter-1">
                            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 0.431373 0 0 0 0 0.458824 0 0 0 1.000000 0"/>
                        </filter>
                        <path d="M17.5500001,22.6666667 C17.9089852,22.6666667 18.2000001,22.9651435 18.2000001,23.3333333 C18.2000001,23.7015232 17.9089852,24 17.5500001,24 L17.5500001,24 L0.650000003,24 C0.291014914,24 4.08562073e-14,23.7015232 4.08562073e-14,23.3333333 C4.08562073e-14,22.9651435 0.291014914,22.6666667 0.650000003,22.6666667 L0.650000003,22.6666667 Z M25.3500001,17.3333333 C25.7089852,17.3333333 26,17.6318102 26,18 C26,18.3681898 25.7089852,18.6666667 25.3500001,18.6666667 L25.3500001,18.6666667 L0.650000003,18.6666667 C0.291014914,18.6666667 1.59872116e-14,18.3681898 1.59872116e-14,18 C1.59872116e-14,17.6318102 0.291014914,17.3333333 0.650000003,17.3333333 L0.650000003,17.3333333 Z M25.3500001,11.3333333 C25.7089852,11.3333333 26,11.6318102 26,12 C26,12.3681898 25.7089852,12.6666667 25.3500001,12.6666667 L25.3500001,12.6666667 L0.650000003,12.6666667 C0.291014914,12.6666667 1.59872116e-14,12.3681898 1.59872116e-14,12 C1.59872116e-14,11.6318102 0.291014914,11.3333333 0.650000003,11.3333333 L0.650000003,11.3333333 Z M25.3500001,5.33333333 C25.7089852,5.33333333 26,5.63181017 26,6 C26,6.36818983 25.7089852,6.66666667 25.3500001,6.66666667 L25.3500001,6.66666667 L0.650000003,6.66666667 C0.291014914,6.66666667 1.59872116e-14,6.36818983 1.59872116e-14,6 C1.59872116e-14,5.63181017 0.291014914,5.33333333 0.650000003,5.33333333 L0.650000003,5.33333333 Z M25.3500001,0 C25.7089852,0 26,0.298476833 26,0.666666667 C26,1.0348565 25.7089852,1.33333333 25.3500001,1.33333333 L25.3500001,1.33333333 L0.650000003,1.33333333 C0.291014914,1.33333333 1.59872116e-14,1.0348565 1.59872116e-14,0.666666667 C1.59872116e-14,0.298476833 0.291014914,0 0.650000003,0 L0.650000003,0 Z" id="notices-icon-path-2"/>
                    </defs>
                    <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Notices_Red" transform="translate(-181.000000, -18.000000)" filter="url(#notices-icon-filter-1)">
                            <g transform="translate(181.000000, 18.000000)">
                                <mask id="notices-icon-mask-3" fill="white">
                                    <use xlinkHref="#notices-icon-path-2"/>
                                </mask>
                                <g id="Combined-Shape" fillRule="nonzero"/>
                                <g id="Group" mask="url(#notices-icon-mask-3)">
                                    <g transform="translate(-2.600000, -4.000000)" id="🎨-color">
                                        <rect id="Primary" fill="#000000" x="0" y="0" width="31.2" height="31.9999999"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    } else {
        return (
            <div className="position-relative">
                {props.hasNewNotices ? <HasNotice /> : ''}
                <svg width="26px" height="24px" viewBox="0 0 26 24">
                    <title>Notices_Red_Grey</title>
                    <defs>
                        <filter colorInterpolationFilters="auto" id="notices-icon-filter-1">
                            <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.396078 0 0 0 0 0.396078 0 0 0 0 0.396078 0 0 0 1.000000 0"/>
                        </filter>
                        <path d="M17.5500001,22.6666667 C17.9089852,22.6666667 18.2000001,22.9651435 18.2000001,23.3333333 C18.2000001,23.7015232 17.9089852,24 17.5500001,24 L17.5500001,24 L0.650000003,24 C0.291014914,24 4.08562073e-14,23.7015232 4.08562073e-14,23.3333333 C4.08562073e-14,22.9651435 0.291014914,22.6666667 0.650000003,22.6666667 L0.650000003,22.6666667 Z M25.3500001,17.3333333 C25.7089852,17.3333333 26,17.6318102 26,18 C26,18.3681898 25.7089852,18.6666667 25.3500001,18.6666667 L25.3500001,18.6666667 L0.650000003,18.6666667 C0.291014914,18.6666667 1.59872116e-14,18.3681898 1.59872116e-14,18 C1.59872116e-14,17.6318102 0.291014914,17.3333333 0.650000003,17.3333333 L0.650000003,17.3333333 Z M25.3500001,11.3333333 C25.7089852,11.3333333 26,11.6318102 26,12 C26,12.3681898 25.7089852,12.6666667 25.3500001,12.6666667 L25.3500001,12.6666667 L0.650000003,12.6666667 C0.291014914,12.6666667 1.59872116e-14,12.3681898 1.59872116e-14,12 C1.59872116e-14,11.6318102 0.291014914,11.3333333 0.650000003,11.3333333 L0.650000003,11.3333333 Z M25.3500001,5.33333333 C25.7089852,5.33333333 26,5.63181017 26,6 C26,6.36818983 25.7089852,6.66666667 25.3500001,6.66666667 L25.3500001,6.66666667 L0.650000003,6.66666667 C0.291014914,6.66666667 1.59872116e-14,6.36818983 1.59872116e-14,6 C1.59872116e-14,5.63181017 0.291014914,5.33333333 0.650000003,5.33333333 L0.650000003,5.33333333 Z M25.3500001,0 C25.7089852,0 26,0.298476833 26,0.666666667 C26,1.0348565 25.7089852,1.33333333 25.3500001,1.33333333 L25.3500001,1.33333333 L0.650000003,1.33333333 C0.291014914,1.33333333 1.59872116e-14,1.0348565 1.59872116e-14,0.666666667 C1.59872116e-14,0.298476833 0.291014914,0 0.650000003,0 L0.650000003,0 Z" id="notices-icon-path-2"/>
                    </defs>
                    <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
                        <g id="Notices_Red_Grey" transform="translate(-181.000000, -18.000000)" filter="url(#notices-icon-filter-1)">
                            <g transform="translate(181.000000, 18.000000)">
                                <mask id="notices-icon-mask-3" fill="white">
                                    <use xlinkHref="#notices-icon-path-2"/>
                                </mask>
                                <g id="Combined-Shape" fillRule="nonzero"/>
                                <g id="Group" mask="url(#notices-icon-mask-3)">
                                    <g transform="translate(-2.600000, -4.000000)" id="🎨-color">
                                        <rect id="Primary" fill="#000000" x="0" y="0" width="31.2" height="31.9999999"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }

};

NoticesIcon.propTypes = {
    isActive: PropTypes.bool.isRequired,
    hasNewNotices: PropTypes.bool
};

export default NoticesIcon;