import React from "react";

const SmallIconFavourite = (props) => {

    return (
        <svg viewBox="0 0 20 18">
            <g id="FILTER-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-657.000000, -44.000000)" fill="#FF6E75">
                    <g id="Favourite_Small" transform="translate(657.000000, 44.000000)">
                        <path d="M18.4577648,1.69093083 C17.4708237,0.608272235 16.1320002,0 14.7358827,0 C13.3397651,0 12.0009416,0.608272235 11.0139417,1.69093083 L11.0139417,1.69093083 C10.46673,2.29091887 9.53275356,2.29091887 8.98554182,1.69093083 L8.98554182,1.69093083 C6.92997724,-0.562894485 3.59724802,-0.56289329 1.54167167,1.69093083 C-0.513890558,3.94474418 -0.513890558,7.59890819 1.54167167,9.8527335 L2.55588338,10.9647514 L8.2739301,17.2343401 C9.20509475,18.25522 10.7943888,18.25522 11.7255534,17.2343401 L17.4436472,10.9647514 L18.4577648,9.8527335 C19.4451765,8.77058894 20,7.3025781 20,5.77183216 C20,4.24108623 19.4451765,2.77307539 18.4577648,1.69093083 Z" id="small-favourite-pat-Copy"/>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default SmallIconFavourite;