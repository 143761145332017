import React from "react";

const HeartIconOff = (props) => {

    return (
        <svg viewBox="0 0 26 24">
            <title>Heart Off</title>
            <g id="FILTER-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-396.000000, -187.000000)" stroke="#3F3F3F" strokeWidth="1.2">
                    <path d="M419.149318,190.066693 C417.964988,188.743444 416.3584,188 414.683059,188 C413.007718,188 411.40113,188.743444 410.21673,190.066693 L410.21673,190.066693 C409.560076,190.800012 408.439304,190.800012 407.78265,190.066693 L407.78265,190.066693 C405.315973,187.312018 401.316698,187.312019 398.850006,190.066693 C396.383331,192.821354 396.383331,197.287554 398.850006,200.04223 L400.06706,201.401363 L406.928716,209.064193 C408.046114,210.311936 409.953267,210.311936 411.070664,209.064193 L417.932377,201.401363 L419.149318,200.04223 C420.334212,198.719609 421,196.925373 421,195.054462 C421,193.18355 420.334212,191.389314 419.149318,190.066693 Z" id="Heart-Off" />
                </g>
            </g>
        </svg>
    );

};

export default HeartIconOff;