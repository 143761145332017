import React from "react";
import PropTypes from "prop-types";

const SmallIconRecommendations = (props) => {

    return (
        <svg viewBox="0 0 17 23">
            <defs>
                <polygon id="small-recommendations-path-1" points="0 0 10.7576773 0 10.7576773 17.2403351 0 17.2403351"/>
                <polygon id="small-recommendations-path-3" points="0 0 3.88525133 0 3.88525133 0.901197957 0 0.901197957"/>
            </defs>
            <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-584.000000, -140.000000)">
                    <g id="All-Recomended-1" transform="translate(584.000000, 140.000000)">
                        <g id="Group-3" transform="translate(6.242323, 5.759665)">
                            <mask id="small-recommendations-mask-2" fill="white">
                                <use xlinkHref="#small-recommendations-path-1"/>
                            </mask>
                            <g id="Clip-2"/>
                            <path d="M0.00878113888,7.37730037 C0.00878113888,5.03457156 0.00742300044,2.69193259 0.0127650117,0.349293616 C0.0131271819,0.232862411 0.0722514755,0.116431205 0.103941373,0 C0.202451681,0.0585749582 0.316806938,0.100889077 0.397299277,0.177970847 C3.78929531,3.42636554 7.17875616,6.67736556 10.5651386,9.93150994 C10.6490715,10.0120954 10.6944333,10.13221 10.7577226,10.2339077 C10.6379348,10.2612187 10.5183281,10.3111691 10.3983592,10.3120675 C9.10858035,10.3216803 7.818711,10.3232075 6.52884164,10.327969 C6.11678244,10.3294064 6.04525381,10.4382911 6.2092264,10.8210947 C6.92206799,12.4853601 7.63708261,14.1488171 8.3494715,15.8132623 C8.38433039,15.8945665 8.41665408,15.9818899 8.42416911,16.0686742 C8.43965189,16.2461959 8.34548763,16.3690057 8.1850462,16.4373731 C7.58311925,16.6937733 6.98191663,16.9518804 6.37627742,17.1997459 C6.12538398,17.3024318 5.95507342,17.2103469 5.83166391,16.9238507 C5.2011255,15.4602853 4.57257903,13.9959113 3.9434893,12.5318069 C3.85186023,12.318709 3.7614082,12.105162 3.66959805,11.8922438 C3.5180298,11.5408839 3.37959022,11.5133932 3.11303291,11.7838082 C2.25351236,12.656054 1.39471615,13.5290186 0.535467228,14.4015339 C0.44583009,14.4925407 0.360629539,14.5889378 0.262571943,14.6701522 C0.216847949,14.7079744 0.130741971,14.7469644 0.0897261902,14.7280983 C0.0450887066,14.707615 0.0219098105,14.6227173 0.00724191531,14.5618065 C-0.00633946914,14.5050283 0.0033485851,14.4426801 0.0033485851,14.3828474 C0.00325804254,12.0475752 0.00325804254,9.71239288 0.00325804254,7.37730037 L0.00878113888,7.37730037 Z" id="Fill-1" fill="#FF6E75" mask="url(#small-recommendations-mask-2)"/>
                        </g>
                        <path d="M4.28091576,4.77036799 C4.23609719,4.74593181 4.11477016,4.70972673 4.03482107,4.63174657 C3.33710008,3.95022873 2.64426839,3.26367991 1.95587328,2.57290866 C1.75133763,2.36762679 1.75604585,2.09343849 1.94491763,1.91412006 C2.12564059,1.74243794 2.39473309,1.74261762 2.59347401,1.93774769 C3.28965578,2.62079279 3.98013336,3.30967743 4.67106366,3.99802302 C4.81910075,4.14553849 4.85341638,4.32530611 4.76939289,4.51378811 C4.69261279,4.68573975 4.54846903,4.77243428 4.28091576,4.77036799" id="Fill-4" fill="#FF6E75"/>
                        <path d="M11.4132703,2.31778418 C11.3779587,2.37608962 11.3293374,2.50581698 11.240153,2.59574571 C10.5690515,3.27223257 9.89188366,3.94270023 9.21218064,4.6106524 C8.99578391,4.8233909 8.71483034,4.82653526 8.53102894,4.6349089 C8.35266009,4.44903223 8.36280085,4.18544492 8.57213526,3.97594062 C9.25111394,3.29675859 9.93452921,2.62188883 10.6165863,1.94576132 C10.7699654,1.79384375 10.9480627,1.74173719 11.1515118,1.83409158 C11.3259873,1.91323964 11.4104635,2.05392735 11.4132703,2.31778418" id="Fill-6" fill="#FF6E75"/>
                        <path d="M4.80675075,8.88725322 C4.77361217,8.93971914 4.72435701,9.06019309 4.63942809,9.14562988 C3.96760227,9.82130819 3.29106824,10.4924946 2.61136522,11.1603569 C2.39406307,11.3739938 2.11410547,11.3774077 1.93003244,11.1857813 C1.75075816,10.9992758 1.7599935,10.7368564 1.96969008,10.5269927 C2.64857822,9.84772083 3.33235565,9.17330026 4.01368844,8.49645404 C4.16842568,8.34273969 4.34932972,8.29503524 4.54960987,8.38334687 C4.73250585,8.46411204 4.81725368,8.61567025 4.80675075,8.88725322" id="Fill-8" fill="#FF6E75"/>
                        <g id="Group-12" transform="translate(0.000000, 6.106699)">
                            <mask id="small-recommendations-mask-4" fill="white">
                                <use xlinkHref="#small-recommendations-path-3"/>
                            </mask>
                            <g id="Clip-11"/>
                            <path d="M1.93733017,0.900208167 C1.46252497,0.900208167 0.987719765,0.90245414 0.513005108,0.899399617 C0.206156362,0.897423162 0.000715286248,0.71415182 -1.09736015e-05,0.45119338 C-0.00073339476,0.187336552 0.20362117,0.00262778762 0.511013171,0.00181923758 C1.4681386,-0.000516573635 2.42526404,-0.000786090313 3.38229893,0.00208875426 C3.68335295,0.00298714319 3.89205356,0.199464802 3.88508178,0.460626464 C3.87829109,0.714421337 3.67556629,0.896884128 3.38429087,0.899219939 C2.90197063,0.902993173 2.4196504,0.900208167 1.93733017,0.900208167" id="Fill-10" fill="#FF6E75" mask="url(#small-recommendations-mask-4)"/>
                        </g>
                        <path d="M7.06152314,1.93096485 C7.06152314,2.40208001 7.06360562,2.87319516 7.0607988,3.34422048 C7.05898795,3.64877433 6.87400949,3.85414604 6.61007792,3.85468579 C6.34850046,3.85513426 6.15700293,3.64661819 6.15609751,3.34547822 C6.15356232,2.39570145 6.15320015,1.44610435 6.15636914,0.496327573 C6.15745565,0.195007926 6.35230324,-0.00623119461 6.6200376,0.000147366787 C6.88170561,0.00643608929 7.05817306,0.199410031 7.06043663,0.495249506 C7.06414887,0.97373145 7.06152314,1.45239307 7.06152314,1.93096485" id="Fill-13" fill="#FF6E75"/>
                        <path d="M11.2694073,7.00712284 C10.8022076,7.007033 10.335008,7.00873994 9.86780839,7.00649397 C9.54429981,7.00487687 9.33252076,6.82313279 9.33396205,6.55487385 C9.33541812,6.2884117 9.5487364,6.10864407 9.87260715,6.10810504 C10.814612,6.10684729 11.7566168,6.10657778 12.6985311,6.10837455 C13.0124421,6.10900343 13.2118169,6.28167378 13.2175211,6.54858513 C13.2232252,6.82349214 13.0179652,7.00532606 12.6936418,7.00667365 C12.2189271,7.0086501 11.7442125,7.00721268 11.2694073,7.00712284" id="Fill-15" fill="#FF6E75"/>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default SmallIconRecommendations;