import React, {useContext, useEffect} from 'react';
import { Route, useHistory } from 'react-router-dom';
import UserContext from "../../UserContext";

const PrivateRoute = ({component: Component, ...rest}) => {

    const { isLoggedIn } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if (!isLoggedIn()) {
            //todo: Log the requested route and redirect after login
            history.push('/login');
        }
    });

	return (
        <Route {...rest} render={props => (<Component {...props} />)} />
	);

};

export default PrivateRoute;