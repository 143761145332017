import React from "react";

const BackIcon = () => {

    return (
        <svg width="12px" viewBox="0 0 12 22">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeDasharray="0,0" strokeLinecap="round" strokeLinejoin="round">
                <g transform="translate(-446.000000, -190.000000)" stroke="#3F3F3F" strokeWidth="1.95">
                    <path d="M457,211 L447,201 L457,191" />
                </g>
            </g>
        </svg>
    );

};

export default BackIcon;