import React from "react";
import PropTypes from "prop-types";
import GenericError from "../../components/GenericError/GenericError";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        //todo: Log error to sentry
        //logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            if (this.props.isAbsolute) {
                return <div style={{position: 'absolute', top: '60px'}}><GenericError /></div>;
            }

            return <GenericError />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    isAbsolute: PropTypes.bool
}

export default ErrorBoundary;