import React from "react";
import PropTypes from "prop-types";
import AvatarIcon from "../../icons/AvatarIcon";
import styles from "./Avatar.module.scss";
import Image from "../Image/Image";

const Avatar = (props) => {

    const Icon = () => {
        if (props.user && props.user.avatar) {
            return <Image src={`${process.env.REACT_APP_FILE_URL}${props.user.avatar}`} />;
        } else if (props.showFallback && props.user && props.user.first_name) {
            return <div className={`${styles.letter} ${props.shade === 'dark' ? styles.letterLight : ''}`}><span>{props.user.first_name.split('', 1)}</span></div>;
        } else {
            return <AvatarIcon shade={props.shade} />;
        }
    };

    return (
        <div className={styles.avatar} style={{width: props.width, height: props.height}}>
            {Icon()}
        </div>
    );

};

Avatar.propTypes = {
    user: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        avatar: PropTypes.string,
        first_name: PropTypes.string
    })]),
    width: PropTypes.string,
    height: PropTypes.string,
    shade: PropTypes.oneOf(['light', 'dark']),
    showFallback: PropTypes.bool
};

Avatar.defaultProps = {
    width: '36px',
    height: '36px',
    shade: 'dark'
};

export default Avatar;