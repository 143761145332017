import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./FlashMessage.module.scss";
import globals from "../../globals";
import { gsap } from "gsap";
import IconClose from "../../icons/IconClose";

export const FLASH_SUCCESS = 'success';
export const FLASH_INFO = 'info';
export const FLASH_ERROR = 'error';
export const FLASH_WARNING = 'warning';

const mapTypeToClass = {
    [FLASH_SUCCESS]: styles.success,
    [FLASH_INFO]: styles.info,
    [FLASH_ERROR]: styles.error,
    [FLASH_WARNING]: styles.warning,
}

const FlashMessage = (props) => {

    const container = useRef(null);
    let timeout;

    useEffect(() => {
        if (Object.keys(props.message).length > 0) {
            let tl = gsap.timeline();
            tl.set(container.current, {display: 'block'});
            tl.fromTo(container.current, {y: '-200px', opacity: 0}, {y: 0, opacity: 1, duration: 0.2});
            timeout = setTimeout(() => {
                handleClose();
            }, 6000);
        } else if (container.current) {
            gsap.to(container.current, {display: 'none'});
        }

        return function cleanup() {
            clearTimeout(timeout);
        };
    }, [props.message]);

    const handleClose = () => {
        clearTimeout(timeout);
        let tl = gsap.timeline();
        tl.to(container.current, {opacity: 0, duration: 0.2});
        tl.set(container.current, {display: 'none'});
        tl.add(props.handleClose);
    };

    if (Object.keys(props.message).length === 0) {
        return <span />;
    }

    return (
        <div ref={container} className={`${styles.container} ${mapTypeToClass[props.message.type]}`} style={{display: 'none'}}>
            <p dangerouslySetInnerHTML={{__html: globals.noWidows(props.message.message)}} className="m-0" />
            <div className={styles.close} onClick={handleClose}>
                <IconClose />
            </div>
        </div>
    );

};

FlashMessage.propTypes = {
    message: PropTypes.shape({
        message: PropTypes.string,
        type: PropTypes.oneOf([FLASH_SUCCESS, FLASH_INFO, FLASH_ERROR, FLASH_WARNING]),
    }),
    handleClose: PropTypes.func.isRequired
};

export default FlashMessage;