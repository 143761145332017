import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import styles from "./GenericError.module.scss";
import { Offline, Online } from "react-detect-offline";
import iconSadFace from "../../images/icon-sad-face.svg";
import Obfuscate from "react-obfuscate";

const GenericError = (props) => {

    return (
        <div className="text-center p-4">
            <div className="w-100 text-center">
                <img src={iconSadFace} alt="Sad face" width="150px" />
            </div>
            <Online>
                <div className="d-inline-block">Oops! It appears something went wrong. Maybe give it another go below, these things happen from time to time.</div>
            </Online>
            <Offline>
                <div className="d-inline-block">Oops! It appears you are offline and it caused an issue. You can give it another go using the buttons below.</div>
            </Offline>
            <br />
            <div className={styles.buttons}>
                {props.handleRetry ? <Button handleClick={props.handleRetry} className="d-inline-flex mr-2">Try again</Button> : ''}
                <Button handleClick={() => window.location.reload(true)} className="d-inline-flex">Reload page</Button>
            </div>
            <div className="mt-4 text-center">
                <small>If the button{props.handleRetry ? 's' : ''} above {props.handleRetry ? 'don\'t' : 'doesn\'t'} fix it, you can send us an email at <Obfuscate
                    email="hello@hostelify.app"
                    headers={{subject: 'Something went wrong on Hostelify'}}
                /> and we will take a look under the hood.</small>
            </div>
        </div>
    );

};

GenericError.propTypes = {
    handleRetry: PropTypes.func
};

export default GenericError;