import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DisabledOverlay from "../DisabledOverlay/DisabledOverlay";
import styles from "./SearchFilters.module.scss";
import IconClose from "../../icons/IconClose";
import { gsap } from "gsap";
import {FILTER_FAVOURITE, TAG_BAR, TAG_BEACH, TAG_NIGHTLIFE, TAG_RESTAURANT, TAG_SEE_AND_DO} from "../../constants";
import HeartIconOn from "../../icons/HeartIconOn";
import HeartIconOff from "../../icons/HeartIconOff";
import IconTick from "../../icons/IconTick";
import Button from "../Button/Button";

const mapTagsToTitles = {
    [TAG_SEE_AND_DO] : 'See & do',
    [TAG_BAR]: 'Bars',
    [TAG_NIGHTLIFE]: 'Nightlife',
    [TAG_RESTAURANT]: 'Restaurants',
    [TAG_BEACH]: 'Beaches',
    [FILTER_FAVOURITE]: 'Favourites'
};

const SearchFilters = (props) => {

    const [filters, setFilters] = useState(props.appliedFilters);
    const containerRef = useRef(null);
    let tl = gsap.timeline();

    useEffect(() => {
        setFilters(props.appliedFilters);
    }, [props.appliedFilters]);

    useEffect(() => {
        if (props.isVisible) {
            tl.set(containerRef.current, {display: 'block'});
            tl.fromTo(containerRef.current, {opacity: 0}, {opacity: 1, duration: 0.2, ease: 'power4.out'});
        } else {
            tl.to(containerRef.current, {opacity: 0, duration: 0.2});
            tl.set(containerRef.current, {display: 'none'});
        }
    }, [props.isVisible]);

    const applyFilter = (tag, value) => {
        let newFilters = {...filters};
        newFilters[tag] = value;
        setFilters(newFilters);
    }

    const removeFilter = tag => {
        let newFilters = {...filters};
        delete newFilters[tag];
        setFilters(newFilters);
    }

    const handleToggleFilter = tag => {
        if (filters[tag]) {
            removeFilter(tag);
        } else {
            applyFilter(tag, {enabled: true});
        }
    };

    const handleApplyFilters = () => {
        props.updateFilters(filters);
        props.setIsVisible(false);
    };

    const handleClose = () => {
        props.setIsVisible(false);
    };

    const handleClearAll = () => {
        setFilters({});
        props.updateFilters({});
        props.setIsVisible(false);
    };

    const Checkbox = tag => {
        return (
            <div className={styles.checkboxWrap} onClick={() => handleToggleFilter(tag)}>
                <div className={styles.checkbox}>
                    {tag === FILTER_FAVOURITE ? (filters[tag] ? <HeartIconOn /> : <HeartIconOff />) : ''}
                    {tag !== FILTER_FAVOURITE ? (filters[tag] ? <IconTick /> : '') : ''}
                </div>
                <div className={styles.checkTitle}>{mapTagsToTitles[tag]}</div>
            </div>
        );
    };

    return (
        <DisabledOverlay
            isDisabled={props.isVisible}
            handleClick={handleClose}
            className={styles.overlay}
            opacity={0.8}
        >
            <div ref={containerRef} className={styles.container} style={{opacity: 0, display: 'none'}} onClick={e => e.stopPropagation()}>
                <div className="mb-4">
                    <h3>What are you looking for?</h3>
                    <div className={styles.close} onClick={handleClose}>
                        <IconClose />
                    </div>
                </div>
                <div className={styles.boxes}>
                    {Checkbox(TAG_SEE_AND_DO)}
                    {Checkbox(TAG_RESTAURANT)}
                    {Checkbox(TAG_BAR)}
                    {Checkbox(TAG_NIGHTLIFE)}
                    {Checkbox(TAG_BEACH)}
                    {Checkbox(FILTER_FAVOURITE)}
                </div>
                <div className={styles.button}>
                    <Button handleClick={handleApplyFilters}>Apply filters</Button>
                </div>
                <div className={styles.cancel} onClick={handleClearAll}>
                    <span>clear all</span>
                </div>
            </div>
        </DisabledOverlay>
    );

};

SearchFilters.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    updateFilters: PropTypes.func.isRequired,
};

export default SearchFilters;