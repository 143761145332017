const DataStore = {

    setItem(key, data) {
        if (this.isArrayOrObject(data)) {
            data = JSON.stringify(data);
        }

        localStorage.setItem(key, data);
    },

    getItem(key, defaultValue = null) {
        let data = localStorage.getItem(key);

        if (this.isJsonString(data)) {
            data = JSON.parse(data);
        }

        return data ? data : defaultValue;
    },

    clearAll() {
        localStorage.clear();
    },

    isArrayOrObject(data) {
        return Array.isArray(data) || (typeof data === 'object' && data !== null);
    },

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

};

export default DataStore;