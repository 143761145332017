import React from "react";

const HeartIconOn = (props) => {

    return (
        <svg viewBox="0 0 26 24">
            <g id="FILTER-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-358.000000, -187.000000)" fill="#FF6E75" stroke="#FF6E75" strokeWidth="1.2">
                    <path d="M381.149318,190.066693 C379.964988,188.743444 378.3584,188 376.683059,188 C375.007718,188 373.40113,188.743444 372.21673,190.066693 L372.21673,190.066693 C371.560076,190.800012 370.439304,190.800012 369.78265,190.066693 L369.78265,190.066693 C367.315973,187.312018 363.316698,187.312019 360.850006,190.066693 C358.383331,192.821354 358.383331,197.287554 360.850006,200.04223 L362.06706,201.401363 L368.928716,209.064193 C370.046114,210.311936 371.953267,210.311936 373.070664,209.064193 L379.932377,201.401363 L381.149318,200.04223 C382.334212,198.719609 383,196.925373 383,195.054462 C383,193.18355 382.334212,191.389314 381.149318,190.066693 Z" id="Heart-On" />
                </g>
            </g>
        </svg>
    );

};

export default HeartIconOn;