import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

const Logout = (props) => {

    const history = useHistory();
    const { redirectUrl } = useParams();

    useEffect(() => {
        props.logout().then(redirect).catch(redirect);
    }, []);

    const redirect = () => {
        if (redirectUrl) {
            history.push('/' + decodeURIComponent(redirectUrl));
        } else {
            history.push('/');
        }
    };

    return (
        <div style={{width: '100vh', height: '100vh'}}>
            <LoadingOverlay isLoading={true} />
        </div>
    );

};

Logout.propTypes = {
    logout: PropTypes.func.isRequired
};

export default Logout;