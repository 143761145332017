import React from "react";

const IconClose = (props) => {

    return (
        <svg viewBox="0 0 24 24">
            <g id="NEW-NEW-EXTEL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="HOMEPAGE-MENU-NAV" transform="translate(-553.000000, -32.000000)" fill="#3F3F3F">
                    <g id="Cross" transform="translate(552.000000, 31.581475)">
                        <rect id="Rectangle-Copy-13" transform="translate(13.000000, 12.500000) rotate(-45.000000) translate(-13.000000, -12.500000) " x="11" y="-3" width="4" height="31" rx="2" />
                        <rect id="Rectangle-Copy-15" transform="translate(13.000000, 12.500000) rotate(-135.000000) translate(-13.000000, -12.500000) " x="11" y="-3" width="4" height="31" rx="2" />
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default IconClose;