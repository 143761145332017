/**
 * This optional code is used to register a service worker.
 * register() is not called by default.
 *
 * This lets the app load faster on subsequent visits in production, and gives
 * it offline capabilities. However, it also means that developers (and users)
 * will only see deployed updates on subsequent visits to a page, after all the
 * existing tabs open on the page have been closed, since previously cached
 * resources are updated in the background.
 *
 * To learn more about the benefits of this model and instructions on how to opt-in, read https://bit.ly/CRA-PWA
 */

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.0/8 are considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
);

/**
 * Prepare for and register service worker
 * @param config
 */
export function register(config) {
	if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
		if ('serviceWorker' in navigator) {
			//todo: Integrate persist() with add to homescreen action
			initStoragePersistence().then(response => {
				console.log(response);
				if (response === 'prompt') {
					navigator.storage.persist().then(persist => {
						console.log(persist);
					});
				}
			});

			//todo: Integrate storage quota with add to homescreen action
			storageEstimateWrapper().then(({usage, quota}) => {
				console.log(`Using ${usage} out of ${quota} bytes.`);
			});

			// The URL constructor is available in all browsers that support SW.
			const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
			if (publicUrl.origin !== window.location.origin) {
				// Our service worker won't work if PUBLIC_URL is on a different origin
				// from what our page is served on. This might happen if a CDN is used to
				// serve assets; see https://github.com/facebook/create-react-app/issues/2374
				return;
			}

			window.addEventListener('load', () => {
				const swUrl = `${process.env.PUBLIC_URL}/custom-service-worker.js`;

				if (isLocalhost) {
					// This is running on localhost. Let's check if a service worker still exists or not.
					checkValidServiceWorker(swUrl, config);

					// Add some additional logging to localhost, pointing developers to the
					// service worker/PWA documentation.
					navigator.serviceWorker.ready.then(() => {
					console.log(
						'This web app is being served cache-first by a service ' +
						'worker. To learn more, visit https://bit.ly/CRA-PWA'
					);
					});
				} else {
					// Is not localhost. Just register service worker
					registerValidSW(swUrl, config);
				}
			});
		}
	}
}

/**
 * Register valid service worker
 * @param swUrl
 * @param config
 */
function registerValidSW(swUrl, config) {
	navigator.serviceWorker
		.register(swUrl)
		.then(registration => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker == null) {
					return;
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {
							// At this point, the updated precached content has been fetched,
							// but the previous service worker will still serve the older
							// content until all client tabs are closed.
							console.log(
								'New content is available and will be used when all ' +
								'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
							);

							// Execute callback
							if (config && config.onUpdate) {
								config.onUpdate(registration);
							}
						} else {
							// At this point, everything has been precached.
							// It's the perfect time to display a
							// "Content is cached for offline use." message.
							console.log('Content is cached for offline use.');

							// Execute callback
							if (config && config.onSuccess) {
								config.onSuccess(registration);
							}
						}
					}
				};
			};
	})
	.catch(error => {
		console.error('Error during service worker registration:', error);
	});
}

function checkValidServiceWorker(swUrl, config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl, {
		headers: { 'Service-Worker': 'script' }
	})
	.then(response => {
		// Ensure service worker exists, and that we really are getting a JS file.
		const contentType = response.headers.get('content-type');
		if (
			response.status === 404 ||
			(contentType != null && contentType.indexOf('javascript') === -1)
		) {
			// No service worker found. Probably a different app. Reload the page.
			navigator.serviceWorker.ready.then(registration => {
			registration.unregister().then(() => {
			window.location.reload();
			});
			});
		} else {
			// Service worker found. Proceed as normal.
			registerValidSW(swUrl, config);
		}
	})
	.catch(() => {
		console.log('No internet connection found. App is running in offline mode.');
	});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(registration => {
			registration.unregister();
		});
	}
}

/**
 * todo: Improve this functionality to maybe specify to users that their data is not persistent
 * Or I could use this to specify whether or not to display a store offline button
 * Keep in mind trying to do this may prompt the user for permission, so a good idea will be to only
 * actually call persist() after the users clicks the "store offline" button so they know what the perm is for
 */
async function initStoragePersistence() {
	const persist = await tryPersistWithoutPromtingUser();
	switch (persist) {
		case "never":
			console.log("Not possible to persist storage");
			break;
		case "persisted":
			console.log("Successfully persisted storage silently");
			break;
		case "prompt":
			console.log("Not persisted, but we may prompt user when we want to.");
			break;
        default:
            console.log("Presist case not recognised.");
            break;
	}

	return persist;
}

/**
 * Check if storage is persisted already.
 * @returns {Promise<boolean>} Promise resolved with true if current origin is
 * using persistent storage, false if not, and undefined if the API is not present.
*/
async function isStoragePersisted() {
	return await navigator.storage && navigator.storage.persisted ? navigator.storage.persisted() : undefined;
}

/**
 * Tries to convert to persisted storage.
 * @returns {Promise<boolean>} Promise resolved with true if successfully
 * persisted the storage, false if not, and undefined if the API is not present.
*/
async function persist() {
	return await navigator.storage && navigator.storage.persist ? navigator.storage.persist() : undefined;
}

/**
 * Tries to persist storage without ever prompting user.
 * @returns {Promise<string>}
 * "never" In case persisting is not ever possible. Caller don't bother asking user for permission.
 * "prompt" In case persisting would be possible if prompting user first.
 * "persisted" In case this call successfully silently persisted the storage, or if it was already persisted.
*/
async function tryPersistWithoutPromtingUser() {
	if (!navigator.storage || !navigator.storage.persisted) {
		return "never";
	}
	let persisted = await navigator.storage.persisted();
	if (persisted) {
		return "persisted";
	}
	if (!navigator.permissions || !navigator.permissions.query) {
		return "prompt"; // It MAY be successful to prompt. Don't know.
	}
	const permission = await navigator.permissions.query({
		name: "persistent-storage"
	});
	if (permission.state === "granted") {
		persisted = await navigator.storage.persist();
		if (persisted) {
			return "persisted";
		} else {
			throw new Error("Failed to persist");
		}
	}
	if (permission.state === "prompt") {
		return "prompt";
	}
	return "never";
}

/**
 * Estimate the total storage being used in the browser
 * @returns {Promise<StorageEstimate>|Promise<unknown>|Promise<{usage: number, quota: number}>}
 */
function storageEstimateWrapper() {
	if ('storage' in navigator && 'estimate' in navigator.storage) {
		// We've got the real thing! Return its response.
		return navigator.storage.estimate();
	}

	if ('webkitTemporaryStorage' in navigator && 'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
		// Return a promise-based wrapper that will follow the expected interface.
		return new Promise((resolve, reject) => {
			navigator.webkitTemporaryStorage.queryUsageAndQuota(
				(usage, quota) => {resolve({usage: usage, quota: quota})},
				reject
			);
		});
	}

	// If we can't estimate the values, return a Promise that resolves with NaN.
	return Promise.resolve({usage: NaN, quota: NaN});
}