import React from "react";
import PropTypes from "prop-types";
import BackIcon from "../../icons/BackIcon";
import ImageBanner from "../ImageBanner/ImageBanner";
import imageFailed from "../../images/image-failed.png";
import styles from "./LoadingPage.module.scss";

const LoadingPage = (props) => {

    return (
        <React.Fragment>
            <div className="d-flex align-items-center mb-3">
                <div className="px-1 flex-grow-1" onClick={props.handleBack}>
                    <BackIcon />
                </div>
                <div className={styles.favourite}>
                    <span><div className={styles.stripe} style={{width: '150px'}} /></span>
                </div>
            </div>
            <ImageBanner image={imageFailed} className="mb-3" />
            <div className={styles.title}>
                <div style={{marginBottom: '30px'}}>
                    <div className={styles.stripe} style={{width: '220px', height: '10px'}} />
                </div>
                <div>
                    <div className={styles.stripe} style={{width: '100%', marginBottom: '10px'}} />
                    <div className={styles.stripe} style={{width: '80%', marginBottom: '10px'}} />
                    <div className={styles.stripe} style={{width: '90%', marginBottom: '10px'}} />
                    <div className={styles.stripe} style={{width: '60%', marginBottom: '10px'}} />
                    <div className={styles.stripe} style={{width: '95%', marginBottom: '10px'}} />
                </div>
            </div>
            <div className={styles.panelIcons}>
                <div className={styles.panelIconList}>
                    <div>
                        <div className={styles.icon}/>
                        <div className={styles.stripe} style={{width: '100%', height: '3px'}} />
                    </div>
                    <div>
                        <div className={styles.icon}/>
                        <div className={styles.stripe} style={{width: '100%', height: '3px'}} />
                    </div>
                </div>
                <div className={styles.transport}>
                    <div>
                        <div className={styles.icon}/>
                        <div className={styles.stripe} style={{width: '100%', height: '3px'}} />
                    </div>
                </div>
            </div>
            <div className={styles.details}>
                <div className={styles.stripe} style={{width: '70%', height: '3px', marginBottom: '15px', background: '#FFF'}} />
                <div className={styles.stripe} style={{width: '80%', height: '3px', marginBottom: '15px', background: '#FFF'}} />
                <div className={styles.stripe} style={{width: '60%', height: '3px', marginBottom: '0px', background: '#FFF'}} />
            </div>
        </React.Fragment>
    );

};

LoadingPage.propTypes = {
    handleBack: PropTypes.func.isRequired
};

export default LoadingPage;