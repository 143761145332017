import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './layout/App/App';
import * as InitServiceWorker from './services/InitServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import ScrollToTop from "./layout/ScrollToTop/ScrollToTop";
require('dotenv').config();

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://7b2f3f04fcd943a69bfd0f00a88c6e6f@o174881.ingest.sentry.io/5881982",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const appHeight = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
window.addEventListener('resize', appHeight)
appHeight()

ReactDOM.render(
	<BrowserRouter>
        <LastLocationProvider>
            <ScrollToTop />
            <App />
        </LastLocationProvider>
	</BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
InitServiceWorker.register();
