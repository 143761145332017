export const INFO_LOCATION = 'location';
export const INFO_PHONE = 'phone';
export const INFO_EMAIL = 'email';
export const INFO_WIFI = 'wifi';
export const INFO_SOCIAL = 'social';
export const INFO_OTHER = 'other';

/*
    MARKERS - These are to show on the map and should replicate
    the same items within the "more" nav panel
*/
export const MARKER_HOSTEL = 'hostel';
export const MARKER_FAVOURITE = 'favourites';
export const MARKER_ROUTE = 'routes';
// The below markers should match the items in the "more" nav and the
// "type" field of Recommendations in the API
export const MARKER_SEE_AND_DO = 'see-and-do';
export const MARKER_NIGHTLIFE = 'nightlife';
export const MARKER_EAT_AND_DRINK = 'eat-and-drink';
export const MARKER_BEACH = 'beaches';

export const MARKER_TYPES = [
    MARKER_HOSTEL,
    MARKER_FAVOURITE,
    MARKER_SEE_AND_DO,
    MARKER_NIGHTLIFE,
    MARKER_EAT_AND_DRINK,
    MARKER_BEACH,
    MARKER_ROUTE,
];

/*
    RECOMMENDATION_TYPES - These should be the items in the "more" panel
    which represent the different recommendation types
*/
export const TYPE_SEE_AND_DO = 'see-and-do';
export const TYPE_NIGHTLIFE = 'nightlife';
export const TYPE_EAT_AND_DRINK = 'eat-and-drink';
export const TYPE_BEACH = 'beaches';
export const RECOMMENDATION_TYPES = [
    TYPE_SEE_AND_DO,
    TYPE_NIGHTLIFE,
    TYPE_EAT_AND_DRINK,
    TYPE_BEACH,
];

/*
    TAGS - These work the same as "tags" on a blog post for example, a more fine-grained
    way to label recommendations. ie. "Food & drink" could be restaurant, bar or both.
*/
export const TAG_SEE_AND_DO = 'see-and-do';
export const TAG_BAR = 'bar';
export const TAG_RESTAURANT = 'restaurant';
export const TAG_BEACH = 'beach';
export const TAG_ART = 'art';
export const TAG_NIGHTLIFE = 'nightlife';
export const TAG_TATTOO = 'tattoo';
export const TAG_TYPES = [
    TAG_SEE_AND_DO,
    TAG_BAR,
    TAG_RESTAURANT,
    TAG_BEACH,
    TAG_ART,
    TAG_NIGHTLIFE,
    TAG_TATTOO,
];

export const DETAIL_PHONE = 'phone';
export const DETAIL_HOURS = 'hours';
export const DETAIL_ADDRESS = 'fullAddress';
export const DETAIL_LOCATION = 'location';
export const DETAIL_URL = 'url';
export const DETAIL_RATING = 'rating';
export const DETAIL_INFO = 'info';
export const DETAIL_TYPES = [DETAIL_INFO, DETAIL_PHONE, DETAIL_HOURS, DETAIL_ADDRESS, DETAIL_LOCATION, DETAIL_URL, DETAIL_RATING];

export const BANNER_NOTICE_EXPLAIN = 'explainNoticeboard';
export const BANNER_SEARCH_EXPLAIN = 'explainSearch';

export const SORT_DISTANCE = 'distance';
export const SORT_START_DATE = 'start-date';
export const SORT_MOST_INTERESTED = 'most-interested';
export const SORT_MY_INTERESTED = 'my-interested';

export const GENERIC_ERROR = 'Oops something went wrong. Please check all fields are filled in correctly and give it another try.';
export const FILTER_FAVOURITE = 'favourite';

export const DONT_REDIRECT_TO = ['/login', '/signup', '/account'];

export const LIKE_TYPE_LIKE = 'like';
export const LIKE_TYPE_DISLIKE = 'dislike';