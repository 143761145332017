import React from "react";

const SmallIconSpecial = (props) => {

    return (
        <svg viewBox="0 0 22 22">
            <defs>
                <polygon id="small-special-path-1" points="0 0 22 0 22 22 0 22"/>
            </defs>
            <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-754.000000, -42.000000)">
                    <g id="Group-8" transform="translate(754.000000, 42.000000)">
                        <path d="M11,17.3800825 C7.47648618,17.3800825 4.61992902,14.52353 4.61992902,11.0000219 C4.61992902,7.47647398 7.47648618,4.61996136 11,4.61996136 C14.5235138,4.61996136 17.380071,7.47647398 17.380071,11.0000219 C17.3760037,14.5219749 14.5219587,17.3760152 11,17.3800825 M11,4.22120758 C7.25621423,4.22120758 4.22117459,7.25624226 4.22117459,11.0000219 C4.22117459,14.7438016 7.25621423,17.7788363 11,17.7788363 C14.7437858,17.7788363 17.7788254,14.7438016 17.7788254,11.0000219 C17.7747581,7.25795691 14.7422306,4.22555399 11,4.22120758" id="Fill-1" fill="#FF6E75"/>
                        <path d="M11,10.6012482 C12.0242805,10.6003311 12.8828786,11.3755483 12.985837,12.3947231 C13.0886359,13.4137384 12.4026586,14.3450279 11.3987544,14.5489107 L11.3987544,15.3862936 L10.6012456,15.3862936 L10.6012456,14.5489107 C9.67366299,14.358107 9.00746396,13.5420574 9.00622782,12.5950171 L9.80373669,12.5950171 C9.80373669,13.2556325 10.3394234,13.7912785 11,13.7912785 C11.6605766,13.7912785 12.1962633,13.2556325 12.1962633,12.5950171 C12.1962633,11.9344416 11.6605766,11.3987558 11,11.3987558 C9.97571948,11.3997128 9.11712143,10.6244557 9.01432253,9.60528088 C8.91136414,8.58630545 9.5975009,7.65497611 10.6012456,7.4510933 L10.6012456,6.61371035 L11.3987544,6.61371035 L11.3987544,7.4510933 C12.3264965,7.64189699 12.992536,8.45794661 12.9937722,9.40498685 L12.1962633,9.40498685 C12.1962633,8.74441133 11.6605766,8.20872549 11,8.20872549 C10.3394234,8.20872549 9.80373669,8.74441133 9.80373669,9.40498685 C9.80373669,10.0655624 10.3394234,10.6012482 11,10.6012482 M11,5.41744899 C7.9168307,5.41744899 5.41743789,7.91683772 5.41743789,11.000002 C5.41743789,14.0832061 7.9168307,16.582555 11,16.582555 C14.0831693,16.582555 16.5825621,14.0832061 16.5825621,11.000002 C16.5792923,7.91823336 14.0817737,5.42071878 11,5.41744899" id="Fill-3" fill="#FF6E75"/>
                        <g id="Group-7">
                            <mask id="small-special-mask-2" fill="white">
                                <use xlinkHref="#small-special-path-1"/>
                            </mask>
                            <g id="Clip-6"/>
                            <path d="M11,18.5763439 C6.8158697,18.5763439 3.42366571,15.1844245 3.42366571,11.0000219 C3.42366571,6.81589846 6.8158697,3.4237 11,3.4237 C15.1844493,3.4237 18.5763343,6.81589846 18.5763343,11.0000219 C18.5716689,15.1824308 15.1824157,18.5716784 11,18.5763439 M18.9750887,7.80999165 L18.9750887,3.02494622 L14.1900355,3.02494622 C14.0842858,3.02494622 13.9828826,2.98287769 13.9081161,2.90811136 L11,0 L8.0918839,2.90811136 C8.01711744,2.98287769 7.91571419,3.02494622 7.80996451,3.02494622 L3.02491128,3.02494622 L3.02491128,7.80999165 C3.02491128,7.91574115 2.98284268,8.01714424 2.90807623,8.09191057 L0,11.0000219 L2.90807623,13.9081333 C2.98284268,13.9828996 3.02491128,14.0843027 3.02491128,14.1900522 L3.02491128,18.9750976 L7.80996451,18.9750976 C7.91571419,18.9750976 8.01711744,19.0171662 8.0918839,19.0919325 L11,22 L13.9081161,19.0919325 C13.9828826,19.0171662 14.0842858,18.9750976 14.1900355,18.9750976 L18.9750887,18.9750976 L18.9750887,14.1900522 C18.9750887,14.0843027 19.0171573,13.9828996 19.0919238,13.9081333 L22,11.0000219 L19.0919238,8.09191057 C19.0171573,8.01714424 18.9750887,7.91574115 18.9750887,7.80999165" id="Fill-5" fill="#FF6E75" mask="url(#small-special-mask-2)"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default SmallIconSpecial;