import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./DisabledOverlay.module.scss";
import { gsap} from "gsap";

const DisabledOverlay = (props) => {

    const overlayRef = useRef(null);
    const tl = gsap.timeline();

    useEffect(() => {
        if (props.isDisabled) {
            tl.set(overlayRef.current, {display: 'block'});
            tl.fromTo(overlayRef.current, {opacity: 0}, {opacity: props.opacity, duration: 0.2, ease: 'power4.out'});
        } else {
            tl.to(overlayRef.current, {opacity: 0, duration: 0.2});
            tl.set(overlayRef.current, {display: 'none'});
        }
    }, [props.isDisabled, props.opacity]);

    const handleClick = () => {
        if (props.handleClick && props.isDisabled) {
            props.handleClick();
        }
    };

    return (
        <div onClick={handleClick} className={props.className}>
            <div ref={overlayRef} className={styles.overlay} style={{opacity: props.opacity, display: 'none'}} />
            {props.children}
        </div>
    );

};

DisabledOverlay.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    handleClick: PropTypes.func,
    opacity: PropTypes.number,
    className: PropTypes.string
};

DisabledOverlay.defaultProps = {
    isDisabled: false,
    opacity: 0.6,
    className: '',
}

export default DisabledOverlay;