import React from "react";
import PropTypes from "prop-types";

const MapIcon = (props) => {

    if (props.isActive) {
        return (
            <svg width="27px" height="26px" viewBox="0 0 27 26">
                <title>Map_Red</title>
                <defs>
                    <filter colorInterpolationFilters="auto" id="map-icon-filter-1">
                        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 1.000000 0 0 0 0 0.431373 0 0 0 0 0.458824 0 0 0 1.000000 0"/>
                    </filter>
                    <path d="M26.1360001,0.839999949 C25.9684765,0.730778111 25.7587529,0.708467092 25.5720001,0.779999948 L17.256,4.15199996 C17.032144,4.25080245 16.8866934,4.47132431 16.884,4.71599996 L16.884,24.264 L9.76799999,22.272 L9.76799999,3.16799996 L14.82,4.58399996 C14.977421,4.63996857 15.1510495,4.6277649 15.2990938,4.55032634 C15.4471381,4.47288777 15.5561896,4.33722806 15.6,4.17599996 C15.6441228,4.02161632 15.6245261,3.855989 15.5455933,3.71616506 C15.4666604,3.57634113 15.3349778,3.47398807 15.18,3.43199996 L9.33599998,1.79999995 L9.33599998,1.79999995 L9.21599998,1.79999995 L9.11999998,1.79999995 L9.01199998,1.79999995 L0.443999947,4.12799996 C0.178911819,4.19934728 -0.00394317799,4.4415066 -6.26050504e-05,4.71599996 L-6.26050504e-05,25.116 C-0.00193680712,25.3053064 0.087392808,25.4839656 0.239999946,25.5960001 C0.341530907,25.678235 0.469434206,25.7208694 0.599999948,25.7160001 C0.651374911,25.7274021 0.704624985,25.7274021 0.755999948,25.7160001 L9.15599998,23.436 L17.304,25.7160001 C17.4358246,25.7636597 17.5801755,25.7636597 17.712,25.7160001 L26.0160001,22.356 C26.2441244,22.262315 26.3916912,22.0385847 26.3880677,21.792 L26.3880677,1.34399995 C26.3909374,1.14502088 26.2969457,0.957037531 26.1360001,0.839999949 Z M1.19999995,5.17199997 L8.56799998,3.16799996 L8.56799998,22.272 L1.19999995,24.276 L1.19999995,5.17199997 Z M25.2000001,21.288 L18.084,24.168 L18.084,5.11199997 L25.2000001,2.23199995 L25.2000001,21.288 Z" id="map-icon-path-2"/>
                    <filter colorInterpolationFilters="auto" id="map-icon-filter-4">
                        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.396041 0 0 0 0 0.396102 0 0 0 0 0.396028 0 0 0 1.000000 0"/>
                    </filter>
                </defs>
                <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Map_Red" transform="translate(-87.000000, -17.000000)" filter="url(#map-icon-filter-1)">
                        <g transform="translate(87.000000, 17.000000)">
                            <mask id="map-icon-mask-3" fill="white">
                                <use xlinkHref="#map-icon-path-2"/>
                            </mask>
                            <g id="67" fillRule="nonzero"/>
                            <g filter="url(#map-icon-filter-4)" id="🎨-color">
                                <g mask="url(#map-icon-mask-3)">
                                    <g transform="translate(-1.200000, -1.200000)" fill="#000000" id="Primary">
                                        <rect x="0" y="0" width="28.8" height="28.8"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else {
        return (
            <svg width="27px" height="26px" viewBox="0 0 27 26">
                <title>Map_Red_Grey</title>
                <defs>
                    <filter colorInterpolationFilters="auto" id="map-icon-filter-1">
                        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.396078 0 0 0 0 0.396078 0 0 0 0 0.396078 0 0 0 1.000000 0"/>
                    </filter>
                    <path d="M26.1360001,0.839999949 C25.9684765,0.730778111 25.7587529,0.708467092 25.5720001,0.779999948 L17.256,4.15199996 C17.032144,4.25080245 16.8866934,4.47132431 16.884,4.71599996 L16.884,24.264 L9.76799999,22.272 L9.76799999,3.16799996 L14.82,4.58399996 C14.977421,4.63996857 15.1510495,4.6277649 15.2990938,4.55032634 C15.4471381,4.47288777 15.5561896,4.33722806 15.6,4.17599996 C15.6441228,4.02161632 15.6245261,3.855989 15.5455933,3.71616506 C15.4666604,3.57634113 15.3349778,3.47398807 15.18,3.43199996 L9.33599998,1.79999995 L9.33599998,1.79999995 L9.21599998,1.79999995 L9.11999998,1.79999995 L9.01199998,1.79999995 L0.443999947,4.12799996 C0.178911819,4.19934728 -0.00394317799,4.4415066 -6.26050504e-05,4.71599996 L-6.26050504e-05,25.116 C-0.00193680712,25.3053064 0.087392808,25.4839656 0.239999946,25.5960001 C0.341530907,25.678235 0.469434206,25.7208694 0.599999948,25.7160001 C0.651374911,25.7274021 0.704624985,25.7274021 0.755999948,25.7160001 L9.15599998,23.436 L17.304,25.7160001 C17.4358246,25.7636597 17.5801755,25.7636597 17.712,25.7160001 L26.0160001,22.356 C26.2441244,22.262315 26.3916912,22.0385847 26.3880677,21.792 L26.3880677,1.34399995 C26.3909374,1.14502088 26.2969457,0.957037531 26.1360001,0.839999949 Z M1.19999995,5.17199997 L8.56799998,3.16799996 L8.56799998,22.272 L1.19999995,24.276 L1.19999995,5.17199997 Z M25.2000001,21.288 L18.084,24.168 L18.084,5.11199997 L25.2000001,2.23199995 L25.2000001,21.288 Z" id="map-icon-path-2"/>
                    <filter colorInterpolationFilters="auto" id="map-icon-filter-4">
                        <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.396041 0 0 0 0 0.396102 0 0 0 0 0.396028 0 0 0 1.000000 0"/>
                    </filter>
                </defs>
                <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.5">
                    <g id="Map_Red_Grey" transform="translate(-87.000000, -17.000000)" filter="url(#map-icon-filter-1)">
                        <g transform="translate(87.000000, 17.000000)">
                            <mask id="map-icon-mask-3" fill="white">
                                <use xlinkHref="#map-icon-path-2"/>
                            </mask>
                            <g id="67" fillRule="nonzero"/>
                            <g filter="url(#map-icon-filter-4)" id="🎨-color">
                                <g mask="url(#map-icon-mask-3)">
                                    <g transform="translate(-1.200000, -1.200000)" fill="#000000" id="Primary">
                                        <rect x="0" y="0" width="28.8" height="28.8"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }

};

MapIcon.propTypes = {
    isActive: PropTypes.bool.isRequired
};

export default MapIcon;