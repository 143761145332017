import React from "react";

const BackArrow = (props) => {

    return (
        <svg viewBox="0 0 11 20">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <g id="Layer_1-2-2" data-name="Layer 1-2">
                        <polyline points="9.5 18.5 1.5 10 9.5 1.5" style={{fill: 'none', stroke: '#3f3f3f', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '3px'}} />
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default BackArrow;