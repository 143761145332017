import React from "react";
import styles from "./HasNotice.module.scss";

const HasNotice = (props) => {

    return (
        <div className={styles.container} />
    );

};

export default HasNotice;