import React from "react";

const SmallIconRoute = (props) => {

    return (
        <svg viewBox="0 0 16 16">
            <defs>
                <polygon id="small-route-path-1" points="0 0 16 0 16 16 0 16"/>
            </defs>
            <g id="ICON-SETS" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-595.000000, -79.000000)">
                    <g id="Routes" transform="translate(603.000000, 87.000000) scale(-1, 1) translate(-603.000000, -87.000000) translate(595.000000, 79.000000)">
                        <mask id="small-route-mask-2" fill="white">
                            <use xlinkHref="#small-route-path-1"/>
                        </mask>
                        <g id="Clip-2"/>
                        <path d="M13.36844,11.595374 C12.9171273,11.595374 12.5499708,11.2172342 12.5499708,10.7524205 C12.5499708,10.2875747 12.9171273,9.90943484 13.36844,9.90943484 C13.8197527,9.90943484 14.1869091,10.2875747 14.1869091,10.7524205 C14.1869091,11.2172342 13.8197527,11.595374 13.36844,11.595374 M2.59646628,3.51704012 C2.14515357,3.51704012 1.77799715,3.13890027 1.77799715,2.6741188 C1.77799715,2.20930514 2.14515357,1.8311653 2.59646628,1.8311653 C3.04777898,1.8311653 3.4149354,2.20930514 3.4149354,2.6741188 C3.4149354,3.13890027 3.04777898,3.51704012 2.59646628,3.51704012 M15.2394721,8.861496 C14.2255029,7.81716187 12.5815333,7.81716187 11.5675641,8.861496 C10.7290325,9.72511218 10.5651887,11.0641308 11.1695015,12.1150307 L12.8429085,15.0250886 L3.3017791,15.0250886 C2.47899747,15.0250886 1.80962217,14.3356568 1.80962217,13.4882618 C1.80962217,12.6408346 2.47899747,11.9514351 3.3017791,11.9514351 L7.7889374,11.9514351 C9.12865677,11.9514351 10.2185948,10.8289239 10.2185948,9.44909488 C10.2185948,8.06929801 9.12865677,6.94675469 7.7889374,6.94675469 L3.15709153,6.94675469 L4.83049855,4.03672895 C5.43481133,2.98589348 5.2709675,1.64684264 4.43243587,0.783226461 C3.41846665,-0.261075487 1.77449715,-0.261075487 0.760527935,0.783226461 C-0.0780036993,1.64681046 -0.241847524,2.98586129 0.362465252,4.03669677 L2.59649753,7.92166609 L2.60187253,7.9123003 L7.7889374,7.9123003 C8.61171903,7.9123003 9.28109434,8.60169986 9.28109434,9.44909488 C9.28109434,10.2965221 8.61171903,10.9858895 7.7889374,10.9858895 L3.3017791,10.9858895 C1.96205973,10.9858895 0.872121736,12.1084328 0.872121736,13.4882618 C0.872121736,14.8680587 1.96205973,15.990602 3.3017791,15.990602 L13.3981275,15.990602 L13.4035025,16 L15.6375347,12.1150307 C16.2418475,11.0641308 16.0780037,9.72511218 15.2394721,8.861496" id="Fill-1" fill="#FF6E75" mask="url(#small-route-mask-2)"/>
                    </g>
                </g>
            </g>
        </svg>
    );

};

export default SmallIconRoute;