import React from "react";
import PropTypes from "prop-types";
import LoadingIcon from "../../icons/LoadingIcon";
import styles from "./Loading.module.scss";

const Loading = (props) => {

    return (
        <div className={styles.icon} style={{width: props.width, height: props.height}}>
            <LoadingIcon />
        </div>
    );

};

Loading.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string
};

Loading.defaultProps = {
    width: '50px',
    height: '50px'
}

export default Loading;