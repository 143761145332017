import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styles from "./MorePanel.module.scss";
import { gsap } from "gsap";
import { useLocation, useHistory } from 'react-router-dom';
import WhiteCloseCircleIcon from "../../icons/WhiteCloseCircleIcon";
import SmallIconBeach from "../../icons/SmallIconBeach";
import SmallIconFavourite from "../../icons/SmallIconFavourite";
import SmallIconInfo from "../../icons/SmallIconInfo";
import SmallIconNightlife from "../../icons/SmallIconNightlife";
import SmallIconRoute from "../../icons/SmallIconRoute";
import SmallIconSeeAndDo from "../../icons/SmallIconSeeAndDo";
import SmallIconSpecial from "../../icons/SmallIconSpecial";
import SmallIconEatAndDrink from "../../icons/SmallIconEatAndDrink";
import SmallIconRecommendations from "../../icons/SmallIconRecommendations";
import BackArrow from "../../icons/BackArrow";

const mapSlugsToData = {
    'beaches': {title: 'Beaches', icon: <SmallIconBeach />},
    'favourites': {title: 'Favourites', icon: <SmallIconFavourite />},
    'eat-and-drink': {title: 'Eat & drink', icon: <SmallIconEatAndDrink />},
    'useful-info': {title: 'Useful info', icon: <SmallIconInfo />},
    'nightlife': {title: 'Nightlife', icon: <SmallIconNightlife />},
    'routes': {title: 'Routes', icon: <SmallIconRoute />},
    'see-and-do': {title: 'See & do', icon: <SmallIconSeeAndDo />},
    'specials': {title: 'Specials & deals', icon: <SmallIconSpecial />},
    //'walk': {title: 'Walks & Hikes', icon: <SmallIconWalk />}
};

const MorePanel = (props) => {

    const containerRef = useRef(null);
    const subItemsRef = useRef(null);
    const arrowRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const tl = gsap.timeline();

    useEffect(() => {
        if (props.isMoreVisible) {
            animateIn();
        } else {
            animateOut();
        }
    }, [props.isMoreVisible]);

    useEffect(() => {
        handleAnimateAndClose();
    }, [location]);

    const handleAnimateAndClose = () => {
        if (props.isMoreVisible) {
            props.handleHideMore();
            animateOut();
        }
    };

    const animateIn = () => {
        tl.set(containerRef.current, {display: 'flex'});
        tl.fromTo(containerRef.current, {y: '100%'}, {y: 0, duration: 0.25, ease: 'power4.out'});
    };

    const animateOut = () => {
        tl.to(containerRef.current, {y: '100%', duration: 0.25, ease: 'power4.out'});
        tl.set(containerRef.current, {display: 'none'});
    };

    const handleToggleExpand = event => {
        event.stopPropagation();
        if (isOpen) {
            tl.to(subItemsRef.current, {height: 0, opacity: 0, duration: 0.3});
            tl.to(arrowRef.current, {rotation: '-90deg', duration: 0.3}, 0);
            tl.set(subItemsRef.current, {height: 'auto', display: 'none', opacity: 0}, '+=0.1');
        } else {
            tl.set(subItemsRef.current, {height: 'auto', display: 'block', opacity: 1});
            tl.from(subItemsRef.current, {height: 0, opacity: 0, duration: 0.3});
            tl.to(arrowRef.current, {rotation: '90deg', duration: 0.3}, 0);
        }
        setIsOpen(!isOpen);
    };

    const NavItem = (slug) => {
        if (props.moreNavigation[slug]) {
            let item = mapSlugsToData[slug];
            return (
                <div className={styles.row} onClick={() => history.push(`/${slug}`)}>
                    <div className={styles.inner}>
                        <div className={styles.icon}>{item.icon}</div>
                        <div className={styles.title}>{item.title}</div>
                    </div>
                </div>
            );
        }
    };

    return (
        <div ref={containerRef} className={styles.container} style={{display: 'none'}}>
            <div className={styles.subContainer}>
                <div>
                    <div className={`${styles.row} ${styles.rowHeading}`} onClick={() => history.push(`/recommendations`)}>
                        <div className={styles.inner}>
                            <div className={styles.icon}><SmallIconRecommendations /></div>
                            <div className={styles.title}>All recommendations</div>
                            <div ref={arrowRef} className={styles.arrow} onClick={handleToggleExpand}>
                                <BackArrow />
                            </div>
                        </div>
                    </div>
                    <div ref={subItemsRef} className={styles.recommendations} style={{display: 'none'}}>
                        {NavItem('see-and-do')}
                        {NavItem('beaches')}
                        {NavItem('eat-and-drink')}
                        {NavItem('nightlife')}
                    </div>
                    {NavItem('routes')}
                    {NavItem('useful-info')}
                    {NavItem('specials')}
                </div>
                <div className={styles.close} onClick={handleAnimateAndClose}>
                    <WhiteCloseCircleIcon />
                </div>
            </div>
        </div>
    );

};

MorePanel.propTypes = {
    isMoreVisible: PropTypes.bool.isRequired,
    handleHideMore: PropTypes.func.isRequired,
    moreNavigation: PropTypes.object.isRequired
};

export default MorePanel;