import React from "react";
import PropTypes from "prop-types";

const AvatarIcon = (props) => {

    return (
        <svg viewBox="0 0 33 33">
            <defs>
                <circle id="profile-path-1" cx="16.5" cy="16.5" r="16.5" />
            </defs>
            <g id="profile-filter-ICON-SET" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icons-" transform="translate(-302.000000, -18.000000)">
                    <g id="Avatar" transform="translate(302.000000, 18.000000)">
                        <g id="Oval">
                            <mask id="profile-mask-2" fill="white">
                                <use xlinkHref="#profile-path-1" />
                            </mask>
                            <use id="Mask" fill={props.shade === 'light' ? '#F4F4F4' : '#EEEEEE'} style={{mixBlendMode: 'multiply'}} xlinkHref="#profile-path-1" />
                            <path d="M16.5,50 C23.9558441,50 30,43.9558441 30,36.5 C30,29.0441559 28.4558441,23 16.5,23 C4.54415588,23 3,29.0441559 3,36.5 C3,43.9558441 9.04415588,50 16.5,50 Z" fill="#3F3F3F" mask="url(#profile-mask-2)" />
                        </g>
                        <path d="M16.5,21 C20.004884,21 22,17.6421356 22,13.5 C22,9.35786438 21.6971917,6 16.5,6 C11.3028083,6 11,9.35786438 11,13.5 C11,17.6421356 12.995116,21 16.5,21 Z" id="Oval-Copy" fill="#3F3F3F" />
                    </g>
                </g>
            </g>
        </svg>
    );

};

AvatarIcon.propTypes = {
    shade: PropTypes.oneOf(['light', 'dark'])
};

AvatarIcon.defaultProps = {
    shade: 'dark'
};

export default AvatarIcon;