import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import imageFailed from "../../images/image-failed.png";

const Image = (props) => {

    const [isLoaded, setIsLoaded] = useState(false);
    const imageRef = useRef(null);

    const handleError = () => {
        imageRef.current.src = imageFailed;
    };

    const handleLoad = () => {
        setIsLoaded(true);
    };

    return (
        <React.Fragment>
            {!isLoaded ? <img src={imageFailed} alt="loading..." /> : ''}
            <img
                ref={imageRef}
                src={props.src}
                alt={props.alt}
                onError={handleError}
                onLoad={handleLoad}
                style={isLoaded ? {} : {display: 'none'}}
                {...props}
            />
        </React.Fragment>
    );

};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};

Image.defaultProps = {
    alt: ''
};

export default Image;