import React from "react";

const IconTick = (props) => {

    return (
        <svg viewBox="0 0 240.95 170.45">
            <g id="Layer_2" data-name="Layer 2">
                <path style={{fill: '#FF6E75'}} className="cls-1" d="M107.6,164.08l127-127A21.74,21.74,0,0,0,203.84,6.36L92.23,118,37.11,62.85A21.74,21.74,0,0,0,6.37,93.59l70.49,70.49a21.73,21.73,0,0,0,30.74,0Z"/>
            </g>
        </svg>
    );

};

export default IconTick;